import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription, catchError, of } from 'rxjs';
import * as moment from 'moment';

import { Batch } from '../../classes/batch';
import { Catch } from '../../classes/catch';
import { Species } from '../../classes/species';
import { Community } from '../../classes/community';

import { CatchService } from '../../services/catch.service';
import { SpeciesInfoService } from '../../services/species-info.service';
import { CommunityInfoService } from '../../services/community-info.service';
import { MarketapiProvider } from '../../services/marketplace.service';
import { DefaultInfoService } from '../../services/default-info.service';
import { FisherImageService } from '../../services/fisher-image.service';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../services/base.service';
import { TenantService } from '../../components/tenant/tenant.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


declare var $: any;


@Component({
    selector: 'app-catch',
    templateUrl: './catch.component.html',
    styleUrls: ['./catch.component.scss']
})

export class CatchComponent implements OnInit, OnChanges, OnDestroy {
    private readonly CLASS_NAME = 'CatchComponent';

    /*============================================================================
        Variables
     ============================================================================*/

    version = environment.version;
    promoVideo: SafeResourceUrl = '';

    currYear = new Date().getFullYear();

    cCatch: Catch = new Catch();
    cBatch: Batch = new Batch();

    lat = 0;
    lng = 0;

    boat_img_url = '';

    sassi_id = 0;

    species_writeup = '';
    community_info = '';
    qcCommunitiesInfo: Community[] = [];

    deleteFlag = true;

    currentSpecies: Species | null = null;
    currentSpecStr = '';

    catchId = '';

    // Will be true when /fisher is accessed without ID. Just used so that spinner is not shown then
    noFisher = true;

    // When true the fisher with catchID could not be found
    showError = false;

    // binds to the thank you sms form input in the template
    thankYouMsg = '';

    // The name of the co-op that listed this batch
    batch_coop_name = null;

    // The name of the buyer who bought this batch
    batch_buyer_name = null;

    // set to true if the date the listing was made is more than three weeks ago and a warning should be shown
    show_listing_warning = false;

    typewriterText = '';
    typewriterDisplay = '';

    // array which will keep all the fisher quotes returned from the server in the format {'name' : 'fisher name', 'quote' : 'quote text'}
    quotesArray: { name: string; quote: string, image: string | undefined}[] = [];

    /* array which will keep all the fisher sms prompts returned from the server in the format:
       {'name' : 'fisher name', 'prompt' : 'prompt text'} */
    smsPromptsArray: { name: string; quote: string}[] = [];

    // array which will keep all the fisher video URL's returned from the server
    videosArray: { url: string; intro: string | undefined}[] = [];

    // to use in dynamic class assignment in html - shouldn't use method every time
    isSmallScreen = false;

    /* array which will keep all the fisher cultural perspective images returned from the server in the format:
       {'currentSlide' : 'the currently shown slide in the fisher's slides', 'blurb' : 'rich text blurb',
        'images' : ['img_1 url', ... , 'img_n url']}
     */
    culturalPerspectivesArray: { currentSlide: number; blurb: string | undefined; images: string[] }[] = [];

    // holds the optional contact detail entered by the user sending a thank you message
    user_contact_detail = null;

    reported_establishment = null;
    reporter_name = null;
    reporter_email = null;
    reported_establishment_old_listing = null;
    reporter_name_old_listing = null;
    reporter_email_old_listing = null;

    // stores the description loaded from the server about a certain fishing method
    methodDescription = '';

    centerLatitude = 0;
    centerLongitude = 0;

    qrNotFound = false;
    noStoryContent = false;

    imageSubscription: Subscription[] = [];

    mapOptions: google.maps.MapOptions = {
        center: {lat:  0, lng: 0},
        zoomControl: true,
        zoom: 6.5,
        disableDefaultUI: true,
        streetViewControl: false
    };
    markerOptions: google.maps.MarkerOptions = {};

    /*============================================================================
        Public Methods
     ============================================================================*/

    public replaceBreaks(input: string): string {
        let returnMe = input;
        if (input === null) {
            return input;
        } else {
            try {
                const newVar = input.toString().split('%endline%').join('\n');
                returnMe = newVar;
                // console.log(newVar);
                return returnMe;
            } catch (ex) {
                // alert('ERROR!');
                console.log(ex);
                return input;
            }
        }
    }

    public replaceAll(input: string, search: string, replacement: string): string {
        let returnMe = input;
        try {
            const newVar = input.toString().split(search).join(replacement);
            alert(newVar);
            returnMe = newVar;

            return returnMe;
        } catch (ex) {
            // alert('ERROR!');
            console.log(ex);
            return input;
        }
    }

    public replaceLeadingWhiteSpace(s: string): string {
        // let newStr = '';
        return s.replace(/^\s+/,
            function (m) {
                return m.replace(/\s/g, '.');
            }
        );
        // return newStr;
    }


    /*============================================================================
        Constructor
     ============================================================================*/
    constructor(
        private sanitizer: DomSanitizer,
        private fisherService: CatchService,
        private speciesInfoService: SpeciesInfoService,
        private communityInfoService: CommunityInfoService,
        private route: ActivatedRoute,
        private catchService: CatchService,
        private router: Router,
        private market: MarketapiProvider,
        private defaultInfoService: DefaultInfoService,
        public fisherImageService: FisherImageService,
        private translate: TranslateService,
        public tenantService: TenantService,
        public baseService: BaseService) {
        console.log(this.CLASS_NAME + '.constructor()');

        this.promoVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.tenantService.config.promoVideo);
    }


    ngOnInit() {
        console.log(this.CLASS_NAME + '.ngOnInit()');

        $('.modal').modal();

        // Load Community QC Lookups and Community QC info
        this.communityInfoService.loadCommunities().then(() => {
            this.communityInfoService.loadCommunityLookups().then(() => {
                console.log(this.CLASS_NAME + '.ngOnInit() Loaded QCCommunity lookups');
            });
        })

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && this.deleteFlag) {
                this.cCatch = new Catch();
            }

            this.deleteFlag = true;
        });

        this.route.params.subscribe(params => {
            console.log(this.CLASS_NAME + '.ngOnInit() params: ', params);

            if (params['batch_tag'] ||
                params['species_id'] ||
                params['fisher_id'] ||
                params['community_id'] ||
                params['product_id'] ||
                params['establishment_id']) { // if this parameter is supplied we know we're on a preview page

                // might look sluggish, but prevents long separate if's / multiple methods
                let id = null;
                let id_type = '';

                if (params['batch_tag']) {
                    id = params['batch_tag'];
                    id_type = 'batch_tag';
                    this.noFisher = false;
                    this.catchId = params['batch_tag'];
                } else if (params['community_id']) {
                    id = params['community_id'];
                    id_type = 'community';
                } else if (params['fisher_id']) {
                    id = params['fisher_id'];
                    id_type = 'fisher_id';
                } else if (params['product_id']) {
                    id = params['product_id'];
                    id_type = 'product';
                } else if (params['species_id']) {
                    id = params['species_id'];
                    id_type = 'species';
                } else if (params['establishment_id']) {
                    id = params['establishment_id'];
                    id_type = 'establishment';
                    this.noFisher = false;
                }

                this.fisherService.getH2cData(id, id_type)
                    .pipe(
                        catchError((ex: any) => {
                            console.log(this.CLASS_NAME + '.ngOnInit() ex:', ex);

                            const errorBody = ex;
                            // MICH unsure of how to translate this
                            if (id_type === 'establishment' && errorBody.error_id) {
                                if (errorBody.error_id === 'QR_NOT_FOUND') {
                                    this.qrNotFound = true;
                                } else if (errorBody.error_id === 'EMPTY_QR_CONFIG') {
                                    this.noStoryContent = true;
                                }
                            } else {
                                this.showError = true;
                            }

                            return of(ex);
                    }))
                    .subscribe((tagResult: any) => {

                        console.log(this.CLASS_NAME + '.ngOnInit() tagResult: ', tagResult);

                        if (tagResult.lookup_type) {
                            this.handleTagResult(tagResult);
                            // get buyer name, listing data etc -> all necessary data not included in the tag result
                            this.loadExtraData();
                            this.initUI(); // initialize jQuery components and typewriter effect

                            // log view of this listing
                            console.log(this.CLASS_NAME + '.ngOnInit()  Logging view of', this.catchId);

                            this.market.logView(this.catchId)
                                .pipe(
                                    catchError((error: any) => {
                                    console.log('error: ', error);

                                    return of(error);
                                }))
                                .subscribe((result: any) => {
                                    console.log('result: ', result);
                                });

                        } else {
                            this.buildPreview(tagResult, id_type);
                            this.loadExtraData();
                            this.initUI();
                        }
                    });

            } else { // normal operation
                console.log('ID param not present');
            }
        });

        // log view of this listing
        console.log(this.CLASS_NAME + '.ngOnInit(): Logging view of', this.catchId);

        this.market.logView(this.catchId)
             .pipe(
                catchError((error: any) => {
                console.log(this.CLASS_NAME + '.ngOnInit() logView error: ', error);

                return of(error);
            }))
            .subscribe((result: any) => {
                console.log(this.CLASS_NAME + '.ngOnInit() logView result: ', result);
            })


    }


    ngOnChanges() {
        this.getBoatTypeImage();
    }


    initUI(): void {
        console.log(this.CLASS_NAME + '.initUI()');

        // check if mobile
        this.isSmallScreen = this.checkIfSmallScreen();

        // initialize typewriter effect
        this.translate.get('INTRO_TEXT').subscribe((translated: string) => {
            this.typewriterText = translated;
            this.typewriter(this);
        });

        // check if mobile device
        let isMobile = /Android|iPhone/i.test(window.navigator.userAgent);

        // initialize modal and slider
        $(document).ready(() => {
            console.log(this.CLASS_NAME + '.initUI() Document ready');
            $('.modal').modal();
            $('.materialboxed').materialbox();
            $('.tooltipped').tooltip({ delay: 50 });
            $('.fixed-action-btn').floatingActionButton({ hoverEnabled: !isMobile });
            // $('.fade-in-later').css({opacity: 1}); // fade material icons in after document ready, also see class in app.component.scss
        });
    }


    /**
     * Needed to het the catch to pass onto child components
     * @returns {Catch}
     */
    getThisCatch(): Catch | null {
        return this.cCatch;
    }

    /*============================================================================
        Void Methods
     ============================================================================*/

    debugButton(): void {
        // const test = response.json()['species'];
        // const test = this.fisher;
        // console.log(this.fisherInfo[1]['common_name']);
        // this.filterDataById();
        // alert(this.currentSpecies);
        console.log('Current species:\n' + this.currentSpecies);

    }


    buildPreview(mainObj: any, id_type: string) {

        let templateData = new Batch({
            'fishers': [
                {
                    'abalobi_id__c': 'demofisher',
                    'FirstName__c': 'Demo',
                    'LastName__c': 'Fisher',
                    'h2c_pic_profile__c': 'https://res.cloudinary.com/techairos/image/upload/v1508848176/fisherPlaceholder_okjsoe.jpg',
                    'h2c_pic_profile_2__c': undefined,
                    'h2c_pic_boat__c': 'https://res.cloudinary.com/techairos/image/upload/v1558433496/pexels-photo-1150978_hlz6as.jpg',
                    'h2c_boat_description__c': 'This is a test boat',
                    'h2c_pic_cultural_1__c': undefined,
                    'h2c_pic_cultural_2__c': undefined,
                    'h2c_pic_cultural_3__c': undefined,
                    'h2c_pic_cultural_4__c': undefined,
                    'h2c_cultural_perspectives_blurb__c': undefined,
                    'image_url__c': 'https://res.cloudinary.com/techairos/image/upload/v1508848176/fisherPlaceholder_okjsoe.jpg',
                    'primary_community__c': 'democommunity',
                    'contact_mobile_num__c': undefined,
                    'h2c_blurb__c': 'This is a test fisher profile',
                    'h2c_quote_1__c': 'Give a man a fish and he will eat for a day. Give a fish a man and the fish will suffocate.',
                    'h2c_quote_1_pic__c': undefined,
                    'h2c_quote_1_name__c': undefined,
                    'h2c_quote_2__c': 'I enjoy taking long walks on the beach.',
                    'h2c_quote_2_pic__c': undefined,
                    'h2c_quote_2_name__c': undefined,
                    'h2c_quote_3__c': 'Did you know that the ocean is quite large? There\'s also fish in it. So that\'s pretty neat.',
                    'h2c_quote_3_pic__c': undefined,
                    'h2c_quote_3_name__c': undefined,
                    'h2c_quote_4__c': undefined,
                    'h2c_quote_4_pic__c': undefined,
                    'h2c_quote_4_name__c': undefined,
                    'h2c_sms_prompt__c': undefined,
                    'h2c_sms_prompt_name__c': undefined,
                    'h2c_video_url__c': undefined,
                    'h2c_video_intro__c': undefined
                }
            ],
            'species': [
                {
                    'name_eng__c': 'Cape Bream',
                    'image_url__c': 'http://res.cloudinary.com/techairos/image/upload/v1493206841/be5nfscvzqk1fhyxhlpx.png',
                    'Ablb_Species_Writeup__c': 'The <em>Cape Bream</em> (Pachymetopon blochii), also known as the Hottentot seabream, is a species of sea bream in the family Sparidae, native to the southwestern coast of Africa. The species occurs in the southeastern Atlantic, from Angola to Cape Agulhas at the southern tip of South Africa. Vagrants have been recorded as far east as the mouth of the Tsitsikamma River. The Cape Bream inhabits kelp beds on shallow rocky reefs, as well as subtidal reefs and offshore pinnacles in deeper waters (up to 55 m) and blinders (to depths of 55 m). Juveniles are restricted to the kelp beds. The Cape Bream is an important species in a variety of smaller-scale fisheries (artisanal linefishery, recreational shore and ski-boat fishery) and occurs as bycatch in gill-net fishery. However, it appears not to be exploited to an extent that is damaging stocks, and is common in a number of protected areas in its range. It is therefore classified as Least Concern by the IUCN.',
                    'Ablb_sassi_id__c': 50,
                    'Id': 40,
                    'DEPRECATED_sf_id': 'a032400000IHL6rAAH'
                }
            ],
            'communities': [{
                'Id': 25,
                'Name': 'democommunity',
                'image_url__c': 'https://res.cloudinary.com/techairos/image/upload/v1543868981/qfxlxugtw2lxlo0jjsc1.jpg',
                'gps_lat__c': -33.798807,
                'gps_lon__c': 18.376783,
                'name_eng__c': 'Demo Community',
                'name_afr__c': 'Demo Gemeenskap',
                'comments__c': 'This is a test community for demonstrating Abalobi functionality.<br />\n<h2>Heading:</h2>\n<p style=\"text-align: center;\">Here is a quote:<br /><br />\"I found a fish one time\"&nbsp;<br /><a href=\"http://www.google.com\" target=\"_blank\" rel=\"noopener\">Google hyp</a><br /><br />What\'s that you say Mrs. Robinson?<br /><br /><a href=\"reddit.com\" target=\"_blank\" rel=\"noopener\">reddit\'s link</a><br /><br /><a title=\"Google\'s link\" href=\"http://www.google.com\">www.google.com</a></p>',
                'region__c': 'west_coast',
                'province_abbreviation__c': 'WC',
                'daff_prefix__c': undefined
            }],
            'how': [
                {
                    'boat_imgUrl': 'https://res.cloudinary.com/techairos/image/upload/v1561630656/cdb61cef86bb42c3a755aae30a9ab8d0_ofjpxc.jpg',
                    'boat_description': 'Mr Demo owns a small cruise ship called <em>Demo Boat</em>.',
                    'weather': 'weather_sunny',
                    'sea_condition': 'sea_calm',
                    'method': 'handline',
                    'num_crew': 7
                }
            ]
        });

        switch (id_type) {
            case 'community':
                mainObj = mainObj['abalobi-communities'][0];
                templateData.communities = [{
                    name_eng__c: mainObj.name_eng__c,
                    gps_lat__c: mainObj.gps_lat__c,
                    gps_lon__c: mainObj.gps_lon__c,
                    comments__c: mainObj.comments__c,
                    image_url__c: mainObj.image_url__c
                }];
                break;

            case 'product':
                // Remap product properties to species properties
                templateData.species = [{
                    name_eng__c: mainObj.name_eng,
                    image_url__c: mainObj.image_url,
                    Ablb_Species_Writeup__c: mainObj.description,
                    Ablb_sassi_id__c: 0
                }];
                break;

            case 'species':
                templateData.species = [{
                    name_eng__c: mainObj.name_eng__c,
                    image_url__c: mainObj.image_url__c,
                    Ablb_Species_Writeup__c: mainObj.Ablb_Species_Writeup__c,
                    Ablb_sassi_id__c: mainObj.Ablb_sassi_id__c
                }];
                break;
            default:
                this.showError = true;
                break;
        }

        this.addImageSizes(templateData);
        this.cBatch = templateData;
        this.buildQuotesAndVideosArray();
        this.buildCulturalPerspectivesArray();
        this.updateMapCenter();
    }


    handleTagResult(tagResult: any): void {
        console.log(this.CLASS_NAME + '.handleTagResult()', tagResult);

        if (!tagResult['lookup_type']) {
            return;
        }

        this.cCatch = new Catch();
        this.cBatch = new Batch();

        if (tagResult.lookup_type === 'CATCH') {
            // Handle displaying a catch
            const CATCH = new Catch(tagResult.lookup_data);
            this.setValues(CATCH);
        } else if (tagResult.lookup_type === 'BATCH') {
            // Handle displaying a batch

            // this.addImageSizes(tagResult.lookup_data);
            const BATCH = this.addImageSizes(new Batch(tagResult.lookup_data));

            // Update Method name with correct value
            for (let i = 0; i < BATCH.how.length; i++) {
                const catchMethodKey = this.catchService.getCatchMethodKey(BATCH.how[i].method);

                if (catchMethodKey && typeof catchMethodKey === 'string') {
                    BATCH.how[i].method = catchMethodKey;
                }



            }

            this.cBatch = BATCH;

            // Lookup the qc communities.
            this.qcCommunitiesInfo = this.communityInfoService.buildQcCommunitiesInfo(this.cBatch.communities);
            console.log(this.CLASS_NAME + '.handleTagResult() - qcCommunitiesInfo', this.qcCommunitiesInfo);

            console.log(this.CLASS_NAME + '.handleTagResult() this.cBatch\n', this.cBatch);

            // Validate profile images
            this.setFisherImages(this.cBatch.fishers);

            // Remove Scientific Name label
            this.removeScientificLabel();

            this.buildQuotesAndVideosArray();
            this.buildCulturalPerspectivesArray();
            this.updateMapCenter();
        } else {
            // Error, no catch found
            this.showError = true;
        }
    }

    /**
     * We want to display h2c_pic_profile__c if it's available.  If there is no image
     * OR the url is broken, test image_url__c. If there is no image or the link is broken,
     * substitute the default placeholder image.
     *
     * In any case the result is placed in image_url__c.
     * @param fishers
     */
    setFisherImages(fishers: any) {
        console.log(this.CLASS_NAME + '.setFisherImages() fishers length', fishers.length);

        fishers.forEach((fisher: any, index: number) => {
            this.imageSubscription[index] = this.fisherImageService.getFisherImage(fisher).subscribe((url: string) => {
                console.log(this.CLASS_NAME + '.setFishersImages() url', url);
                fisher.image_url__c = url;
            })
        })
    }


    /**
     * Dump the subscriptions for fisher image testing.
     */
    ngOnDestroy() {
        this.imageSubscription.forEach(subscription => subscription.unsubscribe());
    }


    removeScientificLabel() {
        for (let i = 0; i < this.cBatch.species.length; i++) {
            let species_writeup = this.cBatch.species[i].Ablb_Species_Writeup__c.split(/<strong>[\s\S]*?<\/strong>/g);
            if (species_writeup.length > 1) {
                this.cBatch.species[i].Ablb_Species_Writeup__c = species_writeup[1];
            }
        }
    }


    loadExtraData(): void {

        this.batch_coop_name = null;
        this.batch_buyer_name = null;
        this.show_listing_warning = false;

        // get name of the co-op from the id of the batch
        this.market.getGroupName(this.catchId)
            .subscribe({
                next: (result: any) => {
                    console.log('Batch co-op name: ' + result.name);

                    // handle null and undefined results (the result will be null if the tag is a
                    // catch and not batch tag or the tag does not exist)
                    if (result.name !== null && result.name !== undefined) {
                        this.batch_coop_name = result.name;
                    }
                },
                error: (error: any) => {
                    console.log('Error: ' + error);
                }
            });

        // get name of the buyer from the id of the batch
        this.market.getBuyerNameNew(this.catchId)
            .subscribe({
                next: (result: any) => {
                    console.log('Batch buyer name: ' + result.buyer);

                    // handle null and undefined results (the result will be null if the tag is a
                    // catch and not batch tag or the tag does not exist)
                    if (result.buyer !== null && result.buyer !== undefined) {
                        this.batch_buyer_name = result.buyer;
                    }
                },
                error: (error: any) => {
                    console.log('Error: ' + error);
                }
            });

        let saveListingDate: string;

        this.market.getListingDate(this.catchId)
        .subscribe((result: any) => {

            // handle null and undefined results
            if (result.date !== null && result.date !== undefined) {

                saveListingDate = result.date;
                this.market.getListingExpiryState(this.catchId).subscribe({
                    next: (result: any) => {
                        console.log('*** Expiry state: ', result);

                        let dontExpire = result.dontExpire;
                        if (dontExpire === null || dontExpire === undefined) {
                            dontExpire = false;
                        }
                        console.log(dontExpire);

                        // if the listing date is defined
                        if (!!saveListingDate) {

                            const listing_moment = moment(saveListingDate);
                            const three_weeks_ago = moment().subtract(3, 'weeks');

                            if (listing_moment.isBefore(three_weeks_ago) && !dontExpire) {
                                this.show_listing_warning = true;
                            }
                        }
                    },
                    error: error => {
                        console.log('Error: ' + error);
                    }
                });

            }
        })
    }


    getBoatTypeImage(): void {
        console.log(`Boat type after cleaning: ${this.cCatch.trip_boat_type}`);

        if (this.cCatch.trip_boat_type) {

            switch (this.cCatch.trip_boat_type.toLowerCase()) {
                case 'bakkie':
                case 'boat_bakkie':
                case 'Boat Bakkie':
                    this.boat_img_url = 'assets/images/bakkie.jpg';
                    break;

                case 'chukkie':
                case 'boat_chukkie':
                case 'Boat Chukkie':
                    this.boat_img_url = 'assets/images/chukkie.jpg';
                    break;

                case 'ski':
                case 'boat_skiboat':
                case 'boat_ski':
                case 'Boat Skiboat':
                    this.boat_img_url = 'assets/images/skiboat.JPG';
                    break;
            }
        }
        console.log(`Boat type after processig: ${this.cCatch.trip_boat_type}`);
    }


    setValues(fisher: Catch): void {

        this.cCatch = fisher;
        // this.cCatch.SASSI_ID = 16;
        this.lat = fisher.community_lat;
        this.lng = fisher.community_lon;
        this.sassi_id = this.cCatch.SASSI_ID;
        // this.cCatch.trip_boat_type = 'bakkie';
        this.getBoatTypeImage();

        switch (this.cCatch.fisher_image) {
            case null:
            case undefined:
                this.cCatch.fisher_image = 'http://www.clipartbest.com/cliparts/KTn/XpX/KTnXpXdjc.png';
                break;
        }

        this.currentSpecStr = JSON.stringify(this.currentSpecies);

        this.community_info = this.replaceLeadingWhiteSpace(this.replaceBreaks(fisher.community_info));
        this.species_writeup = this.replaceBreaks(fisher.species_info);
        this.currentSpecies = this.speciesInfoService.getSpeciesSearch(this.sassi_id);
    }


    // called on send sms button click
    sendThankYouSMS(): void {

        if (!this.thankYouMsg) {
            alert(this.translate.instant('HOME_SMS_THANKYOU_MSG'));
        } else {
            // loop through the cell numbers of all the fishers associated with this batch
            for (let i = 0; i < this.cBatch.fishers.length; i++) {

                // if fisher has a number
                if (this.cBatch.fishers[i].contact_mobile_num__c !== null && this.cBatch.fishers[i].contact_mobile_num__c !== undefined) {

                    console.log('Fisher number: ' + this.cBatch.fishers[i].contact_mobile_num__c);

                    $('#sendSMSModal').modal('close');

                    // build sms and send to fisherService to POST it to the appropriate endpoint
                    this.fisherService.sendThankYouSMS(this.cBatch.fishers[i].contact_mobile_num__c,
                        '[Abalobi] SMS from customer eating your catch: ' + this.thankYouMsg,
                        (this.user_contact_detail || 'None provided'),
                        this.cBatch.fishers[i].FirstName__c + ' ' + this.cBatch.fishers[i].LastName__c)
                        .subscribe((response: any) => {
                            console.log(response);

                            alert(JSON.parse(response['_body'])['message']);
                        });
                } else {
                    console.log('Fisher number empty');
                }
            }
        }
    }


    typewriter(that: any): void {
        // DISABLED console.log(that.CLASS_NAME + '.typewriter()', that.typewriterDisplay);

        const totalLength = that.typewriterText.length;
        const currentLength = that.typewriterDisplay.length;
        if (currentLength < totalLength) {
            that.typewriterDisplay += that.typewriterText[currentLength];
        }

        if ((totalLength == 0) || currentLength < totalLength) {
            setTimeout(that.typewriter, 100, that);
        }
    }


    // return extra column classes to spread columns evenly on larger screens
    getColumnSpread(length: number, index: number) {
        let offset = '';
        let l_col_width = 4;

        if (length > 3) {
            l_col_width = 3;
        }

        // if first in array (for example only first column get offset) AND if the offset is positive (thus the row isn't full already
        if (index === 0 && (12 - length * l_col_width) / 2 > 0) {
            offset = ' offset-l' + (12 - length * l_col_width) / 2; // is there any situation in which this would not be an integer? // no
        }

        return 's12 m6 l' + l_col_width + offset;
    }


    getImage(image: string) {

        if (image != null && image !== '') {
            return image;
        }

        return 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png';
    }


    buildQuotesAndVideosArray() {

        // clear any remaining elements in the array from previous loads
        this.quotesArray.length = 0;
        this.videosArray.length = 0;
        this.smsPromptsArray.length = 0;

        // interleave the quotes of the different fishers' quotes

        // first get all the quote 1's videos and sms prompts
        for (const fisher of this.cBatch.fishers) {
            // quote 1
            if (fisher.h2c_quote_1__c !== null && fisher.h2c_quote_1__c !== undefined) {
                this.quotesArray.push({
                    'name': fisher.h2c_quote_1_name__c || (fisher.FirstName__c + ' ' + fisher.LastName__c),
                    'quote': fisher.h2c_quote_1__c, 'image': fisher.h2c_quote_1_pic__c
                });
            }
            // video
            if (fisher.h2c_video_url__c !== null && fisher.h2c_video_url__c !== undefined) {
                this.videosArray.push({ 'url': fisher.h2c_video_url__c, 'intro': fisher.h2c_video_intro__c });
            }
            // sms prompt
            if (fisher.h2c_sms_prompt__c !== null && fisher.h2c_sms_prompt__c !== undefined) {
                this.smsPromptsArray.push({
                    'name': fisher.h2c_sms_prompt_name__c || (fisher.FirstName__c + ' ' + fisher.LastName__c),
                    'quote': fisher.h2c_sms_prompt__c
                });
            } else {
                // smsPromptsArray used in conjunction with fishers array
                // smsPrompts need to be at the same index as the fisher who said it (the specific prompt)
                this.smsPromptsArray.push({ name: '', quote: ''});
            }
        }
        // get all quote 2s
        for (const fisher of this.cBatch.fishers) {
            // quote 2
            if (fisher.h2c_quote_2__c !== null && fisher.h2c_quote_2__c !== undefined) {

                this.quotesArray.push({
                    'name': fisher.h2c_quote_2_name__c || (fisher.FirstName__c + ' ' + fisher.LastName__c),
                    'quote': fisher.h2c_quote_2__c, 'image': fisher.h2c_quote_2_pic__c
                });
            }
        }
        // get all quote 3s
        for (const fisher of this.cBatch.fishers) {
            // quote 3
            if (fisher.h2c_quote_3__c !== null && fisher.h2c_quote_3__c !== undefined) {
                this.quotesArray.push({
                    'name': fisher.h2c_quote_3_name__c || (fisher.FirstName__c + ' ' + fisher.LastName__c),
                    'quote': fisher.h2c_quote_3__c, 'image': fisher.h2c_quote_3_pic__c
                });
            }
        }
        // get all quote 4s
        for (const fisher of this.cBatch.fishers) {
            // quote 4
            if (fisher.h2c_quote_4__c !== null && fisher.h2c_quote_4__c !== undefined) {
                this.quotesArray.push({
                    'name': fisher.h2c_quote_4_name__c || (fisher.FirstName__c + ' ' + fisher.LastName__c),
                    'quote': fisher.h2c_quote_4__c, 'image': fisher.h2c_quote_4_pic__c
                });
            }
        }

        this.defaultInfoService.getDefaultQuotes()
            .subscribe((response: any) => {
                const tmpQuotes = response['abalobi-fixed-quotes'][0];
                const defaultQuotes = [
                    { 'name': tmpQuotes['quote1_name'], 'quote': tmpQuotes['quote1'], 'image': tmpQuotes['quote1_pic'] },
                    { 'name': tmpQuotes['quote2_name'], 'quote': tmpQuotes['quote2'], 'image': tmpQuotes['quote2_pic'] },
                    { 'name': tmpQuotes['quote3_name'], 'quote': tmpQuotes['quote3'], 'image': tmpQuotes['quote3_pic'] },
                    { 'name': tmpQuotes['quote4_name'], 'quote': tmpQuotes['quote4'], 'image': tmpQuotes['quote4_pic'] },
                    { 'name': tmpQuotes['quote5_name'], 'quote': tmpQuotes['quote5'], 'image': tmpQuotes['quote5_pic'] },
                    { 'name': tmpQuotes['quote6_name'], 'quote': tmpQuotes['quote6'], 'image': tmpQuotes['quote6_pic'] },
                ];

                // append the default quotes to the back of the quotes array, in case we don't have 6 quotes yet
                this.quotesArray = this.quotesArray.concat(defaultQuotes);
            });

        // do the same for video, but only if we have no videos at all yet (if we have one video we don't want to show the default one too)
        // if (this.videosArray.length === 0) {
        //     this.videosArray.push(this.defaultInfoService.getDefaultVideo());
        // }

        // console.log(this.quotesArray);
    }


    buildCulturalPerspectivesArray() {

        // clear any remaining elements in the array from previous loads
        this.culturalPerspectivesArray.length = 0;

        for (const fisher of this.cBatch.fishers) {

            // get all images into an array
            const temp = [];
            if (fisher.h2c_pic_cultural_1__c !== null && fisher.h2c_pic_cultural_1__c !== undefined) {
                temp.push(fisher.h2c_pic_cultural_1__c);
            }
            if (fisher.h2c_pic_cultural_2__c !== null && fisher.h2c_pic_cultural_2__c !== undefined) {
                temp.push(fisher.h2c_pic_cultural_2__c);
            }
            if (fisher.h2c_pic_cultural_3__c !== null && fisher.h2c_pic_cultural_3__c !== undefined) {
                temp.push(fisher.h2c_pic_cultural_3__c);
            }
            if (fisher.h2c_pic_cultural_4__c !== null && fisher.h2c_pic_cultural_4__c !== undefined) {
                temp.push(fisher.h2c_pic_cultural_4__c);
            }

            // check if the fisher has any images or text -> if not skip this fisher and move on
            if (temp === null || temp === undefined || fisher.h2c_pic_cultural_1__c === null || fisher.h2c_pic_cultural_1__c == null) {
                continue;
            } else {
                this.culturalPerspectivesArray.push({
                    'currentSlide': 0, 'blurb': fisher.h2c_cultural_perspectives_blurb__c,
                    'images': temp
                });
            }
        }

        console.log(this.culturalPerspectivesArray);
    }


    /* checks if the page is being viewed on a phone or desktop and returns the appropriate class (center text on phone,
        no centering on desktop) */
    checkIfCentered() {
        if (window.innerWidth <= 600) {
            return 'center-align';
        } else {
            return '';
        }
    }


    checkIfSmallScreen() {
        if (screen.width < 601) { // correlates with value in materialize variables, line 219
            return true;
        }
        return false;
    }


    checkIfMedScreenorSmaller() { // terrible function name
        if (screen.width < 993) { // correlates with value in materialize variables
            return true;
        }
        return false;
    }


    getBoatWidth() {
        /* first test if width or height would be limiting factor (photo is usually 4:3)

            max image size is when displayed in materializebox, which is 90% of the device width

            on desktop, the devicePixelRatio is 1 and thus the second part of the equation is just (1 + 1)/2 = 1

            on mobile, a device might have a 360px view but actually be 1080px in width (real resolution)
            here we won't give the full resolution image (because of high DPI and small screen user won't notice the difference)
            but we'll give something better than if the device actually only had 360px

            Others solutions
            Get the parent element width and change width from there
            For the boat, this wouldn't work as it's materializebox requires a bigger image
            The other problem is that modern browsers request images before calculating exact sizes for speed purposes
            Thus, we would have to wait till the dom is loaded before requesting images
        */
        if (window.innerWidth * 3 / 4 > window.innerHeight) {
            // the limiting factor is probably height, so this is just a rough constant gathered from some experimenting
            // console.log('width: ' + 0.59 * window.screen.width * (window.devicePixelRatio + 1) / 2);
            console.log('Height limiting');
            return Math.round(0.66 * window.screen.width * (window.devicePixelRatio + 1) / 2);
        } else {
            // console.log('width: ' + 0.9 * window.screen.width * (window.devicePixelRatio + 1) / 2);
            console.log('Width limiting');
            return Math.round(.9 * window.screen.width * (window.devicePixelRatio + 1) / 2);
        }
    }

    /*
     add image size parameters to Cloudinary URLs
     goal is to make the resolution of requested image is closer to the actually displayed size
      */
    addImageSizes(batch: Batch) {

        console.log(batch);
        console.log('- addImageSizes');

        try {

            // change profile pic sizes to be more equivalent to size actually displayed
            for (const i in batch.communities) {
                if (batch.communities[i].image_url__c != null) {
                    batch.communities[i].image_url__c = batch.communities[i].image_url__c.replace(
                        'upload/', 'upload/w_' + Math.round(screen.width * 0.9) + ',c_limit,q_auto:best/');
                }

                /*
                max size on FHD:            411.7px
                max size on med(w=992px):   410px
                Stays about the same because # of columns changing
                 */
            }

            for (const i in batch.fishers) {
                if (batch.fishers[i].h2c_pic_profile__c != null) {
                    batch.fishers[i].h2c_pic_profile__c = batch.fishers[i].h2c_pic_profile__c?.replace(
                        'upload/', 'upload/w_411,h_411,c_lfill,g_face,q_auto:best/');
                }

                /*
                max size on FHD:            411.7px
                max size on med(w=992px):   410px
                Stays about the same because # of columns changing
                 */
            }
        } catch (e) {
            console.log('ERROR\n' + e);
        }

        // console.log(batch);
        // console.log('- addImageSizes');

        return batch;
    }

    // CULTURAL PERSPECTIVES SLIDER STUFF

    // returns true if the specified slide of the specified fisher's cultural perspectives slideshow should be hidden, true otherwise
    showSlide(fisherIndex: number, slideToCheck: number) {
        if (slideToCheck === this.culturalPerspectivesArray[fisherIndex].currentSlide) {
            return false;
        } else {
            return true;
        }
    }


    nextSlide(fisherIndex: number) {
        // get the number of slides
        const length = this.culturalPerspectivesArray[fisherIndex]['images'].length;
        // go to the next slide, rollover to the first slide after the last slide has been reached
        this.culturalPerspectivesArray[fisherIndex].currentSlide = (this.culturalPerspectivesArray[fisherIndex].currentSlide + 1) % length;
    }


    prevSlide(fisherIndex: number) {
        // get the number of slides
        const length = this.culturalPerspectivesArray[fisherIndex]['images'].length;
        // go to the previous slide, rollover to the last slide after the first slide has been reached
        this.culturalPerspectivesArray[fisherIndex].currentSlide--;
        if (this.culturalPerspectivesArray[fisherIndex].currentSlide === -1) {
            this.culturalPerspectivesArray[fisherIndex].currentSlide = length - 1;
        }
    }


    reportEstablishment() {

        if (!this.reported_establishment) {
            alert('Please enter an establishment name');
        } else {
            // send reporting email
            this.fisherService.reportEstablishment(this.reported_establishment, this.reporter_name,
                this.reporter_email, this.catchId, 'H2C user reported an establishment').subscribe(() => {
                    alert('Establishment reported');
                });

            $('#incorrectEstablishmentModal').modal('close');
        }

        // clear variables
        this.reported_establishment = null;
        this.reporter_name = null;
        this.reporter_email = null;
    }


    reportEstablishmentOldListing() {

        if (!this.reported_establishment_old_listing) {
            alert('Please enter an establishment name');
        } else {
            // send reporting email
            this.fisherService.reportEstablishment(this.reported_establishment_old_listing, this.reporter_name_old_listing,
                this.reporter_email_old_listing, this.catchId, 'H2C user reported an expired QR code').subscribe(() => {
                    alert('Prior story reported');
                });

            $('#oldListingModal').modal('close');
        }


        // clear variables
        this.reported_establishment_old_listing = null;
        this.reporter_name_old_listing = null;
        this.reporter_email_old_listing = null;
    }

    // accepts a key like 'weather_sunny' and returns a human English value like 'Sunny'
    humanify(input: string) {
        const key: string | undefined = this.catchService.getCatchMethodKey(input);
        const translations: { [key: string]: string} = {
            'sea_rough': 'Rough',
            'sea_very_rough': 'Very Rough',
            'sea_calm': 'Calm',
            'weather_cloudy': 'Cloudy',
            'weather_misty': 'Misty',
            'weather_partlycloudy': 'Partly Cloudy',
            'weather_rainy': 'Rainy',
            'weather_sunny': 'Sunny',
            'diving': 'Diving',
            'handline': 'Hand-line',
            'hand': 'By hand',
            'net_gill': 'Gill net',
            'net_hoop': 'Hoop net',
            'net_throw': 'Throw net',
            'net_trek': 'Trek net',
            'pump': 'Pump',
            'rod': 'Fishing rod',
            'trap': 'Trap',
            'not_on_list': 'Other',
            'undefined': 'Not provided',
            'null': 'Not provided'
        };
        if (!key) {
            return translations['undefined'];
        }

        return translations[key];
    }


    methodInfoClicked(method: string) {
        this.methodDescription = '<strong><div class=\'center-align\'>Loading description...</div></strong>';
        this.fisherService.getMethodInfo(method).subscribe((res) => {

            if (!!this.methodDescription) {
                this.methodDescription = res[0].description__c;
            } else {
                this.methodDescription = 'No description found';
            }
        });
    }


    // check if atleast one entry in a given array is !== null && !== undefined
    containsNotOnlyNulls(array: { name: string, quote: string }[]) {
        return array.some((smsPrompt) => {
            return smsPrompt;
        });
    }


    generateMapsURL() {
        // TODO MT refactoring - Should this change to a tenant specific area?
        const returnURL =
            'https://www.google.com/maps/embed/v1/place?key=AIzaSyCZdfw9SPwxciAAin_vhCPq3xIRlOaQAOg&q=Space+Needle,Seattle+WA';
        console.log('Maps URL:\n' + returnURL);
        // return this.sanitizer.bypassSecurityTrustUrl(returnURL);
        return returnURL;
    }


    updateMapCenter() {
        console.log('updateMapCenter();');
        this.centerLatitude = 0;
        this.centerLongitude = 0;
        this.cBatch.communities.forEach(value => {
            this.centerLatitude += value.gps_lat__c;
            this.centerLongitude += value.gps_lon__c;
        });

        if (this.cBatch.communities.length > 0) {
            this.centerLatitude /= this.cBatch.communities.length;
            this.centerLongitude /= this.cBatch.communities.length;
        }

        this.mapOptions.center = {lat: this.centerLatitude, lng: this.centerLongitude};
    }
}
