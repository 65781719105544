import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseService } from './base.service';
import { BatchCommunity } from '../classes/batch';
import { Community } from '../classes/community';
import { TenantService } from '../components/tenant/tenant.service';
import { CommunityLookup } from '../classes/community-lookup';



@Injectable()
export class CommunityInfoService extends BaseService {
    private readonly CLASS_NAME = 'CommunityInfoService';

    private communities: Community[] = [];
    private communityLookups: { [key: string]: CommunityLookup } = {}
    private qcCommunities: string[] = [];
    public qcCommunitiesInfo: Community[] = [];

    constructor(
        private tenantService: TenantService
    ) {
        super();
    }

    public async loadCommunities() {
        const url = `../assets/data/tenants/${this.tenantService.tenantCode}/communities.json`;
        console.log(url);

        const communities = await firstValueFrom(this.httpClient.get<{ [key: string]: Community }>(url));
        const dataArray: Community[] = Object.values(communities);
        this.communities = dataArray;
    }


    public async loadCommunityLookups() {

        const url = `../assets/data/tenants/${this.tenantService.tenantCode}/communityLookup.json`;
        console.log(url);

        this.communityLookups = await firstValueFrom(this.httpClient.get<{ [key: string]: CommunityLookup }>(url));
    }


    /**
     * Fetches the community object given the passed community name.
     * @param name
     * @returns
     */
    public getCommunityByName(name: string): Community | null {
        console.log(this.CLASS_NAME + '.getCommunityByName()');

        if (!this.communities) {
            console.error('Communities data not loaded');
            return null;
        }

        const commuity: Community = this.communities[0];

        if (commuity == null) {
            console.warn(`Community with name '${name}' not found`);
        }

        return commuity;
    }


    /**
     * Looks up the QCCommunities for the passed community name.
     * Note that one fisher community may be associated with multiple
     * QC communities.
     * @param name
     * @returns
     */
    public getQCCommunities(name: string): string[] {
        console.log(this.CLASS_NAME + `.getQCCommunities(${name})`);

        let communityArray: string[] = [];

        if (!this.communityLookups) {
            console.error(this.CLASS_NAME + '.getQCCommunities() - QC Community Lookup data not loaded');

            return communityArray;
        }

        if(this.communityLookups[name] == null) {
            console.error(this.CLASS_NAME + '.getQCCommunities() - Community NOT in Lookup', name);

            return communityArray;
        }

        communityArray = this.communityLookups[name].qcCommunities;
        console.log(this.CLASS_NAME + '.getQCCommunities() - communityArray', communityArray);

        return communityArray;
    }


    /**
     * buildQcCommunitiesInfo
     * Builds up an array of communities info for the POST_CATCH section of the catch component
    * @param communities - array of community names
    * @returns array of objects containing QC community details
     */
    public buildQcCommunitiesInfo(communities: BatchCommunity[]): Community[]  {
        console.log(this.CLASS_NAME + '.buildQcCommunitiesInfo()', communities);

        communities.forEach(item => {

            // Find the qcCommunities for the batch community
            this.qcCommunities = this.getQCCommunities(item.name_eng__c);

            // For each of the qcCommunities (there may be more than one)
            this.qcCommunities.forEach(item => {

                // Fetch QC Community details
                const lookupQCCommunity = this.getCommunityByName(item);

                // If the QC Community exists, add it to the batch details
                if (lookupQCCommunity != null) {

                    // Don't add the community if it's already in the array
                    if (!this.qcCommunitiesInfo.find(element => element.name === lookupQCCommunity.name)) {
                        this.qcCommunitiesInfo.push(lookupQCCommunity);
                    }
                }
            });

            console.log(this.CLASS_NAME + '.buildQcCommunitiesInfo() qcCommunitiesInfo:');
            console.table(this.qcCommunitiesInfo);
        });

        // If no QC Communities found, add the default.
        if (this.qcCommunitiesInfo.length == 0) {
            //this.qcCommunitiesInfo.push(this.getCommunityByName('default'));
        }

        return this.qcCommunitiesInfo;
    }
}
