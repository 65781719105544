<!-- Loading spinner -->
<div *ngIf="false">
    <app-gooey-loader></app-gooey-loader>
</div>

<!-- HEADER -->
<nav class="primary-color">
    <div class="container">
    <div class="nav-wrapper">
        <img class="hide-on-med-and-down" id="navbar-logo" src="https://res.cloudinary.com/techairos/image/upload/c_scale,w_64/v1521026226/navbar_kgbo7e.png">
        <a class="left brand-logo" routerLink="">
            {{ 'ABALOBI' | translate }}
        </a>
        <ul class="right">
            <li><a href="https://www.facebook.com/abalobi.org/"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="https://twitter.com/abalobi_app?lang=en"><i class="fab fa-twitter"></i></a></li>
            <li><a href="https://www.instagram.com/abalobi_app/"><i class="fab fa-instagram"></i></a></li>
        </ul>
    </div>
    </div>
</nav>

<div class="row secondary-color" id="header">
    <div class="container">
        <!-- Large header for large screens -->
        <div class="hide-on-med-and-down">
            <h2>{{ 'FISH_WITH_A_STORY' | translate }}</h2>
            <h6>{{ 'POWERED_BY_UPPERCASE' | translate }}</h6>
            <div class="row">
                <div class="col s2 l2 m2">
                    <img 
                        class="circular-title-pic" 
                        src="https://res.cloudinary.com/techairos/image/upload/v1521726718/circular_vpxxes.png"/>
                </div>
                <div 
                    class="col s10 l10 m10">
                    <h4 class="subtitle-text">
                        {{ typewriterDisplay }}
                        <span 
                            class="cursor">
                        </span>
                    </h4>
                </div>
            </div>
        </div>
        <!-- Smaller header for medium and small screens -->
        <div class="hide-on-large-only">
            <h2 class="mobile">
                {{ 'FISH_WITH_A_STORY' | translate }}
            </h2>
            <h6>{{ 'POWERED_BY_UPPERCASE' | translate }}</h6>
            <div class="row">
                <div class="col s3 l3 m3">
                    <img 
                        class="circular-title-pic-small" 
                        src="https://res.cloudinary.com/techairos/image/upload/v1521726718/circular_vpxxes.png"/>
                </div>
                <div class="col s9 l9 m9">
                    <p class="subtitle-text-small">
                        {{ typewriterDisplay }}
                        <span class="cursor"></span>
                    </p>
                </div>
            </div>
        </div>
        <p>{{ 'THANK_YOU_MESSAGE' | translate }}</p>
    </div>
</div>

<div class="row" id="search-stories">
    <div class="container">
        <h3>{{ 'HOME_FIND' | translate }}</h3>
        <p>{{ 'HOME_SEARCH_STORIES' | translate }}</p>
        <div class="center-align">
            <form>
                <input 
                    id="search-bar" 
                    type="text" 
                    placeholder="{{ 'HOME_TAG_FIND_PLACEHOLDER' | translate }}" 
                    name="search" 
                    [(ngModel)]="tagToFind">
                <a [routerLink]="'/catch/' + tagToFind">
                    <button 
                        class="btn waves-effect waves-light primary-color">
                        <i class="material-icons" color="light">search</i>
                    </button>
                </a>
            </form>
        </div>
    </div>
</div>

<div class="row" id="more-h2c">
    <div class="container">
        <div class="divider"></div>
        <h3>{{ 'HOME_MORE_ABOUT_TITLE' | translate }} </h3>
        <p class="justify">{{ 'HOME_MORE_ABOUT_TEXT' | translate }}</p>
        <div class="center-align"><a href="https://www.fishwithastory.org"><button class="btn waves-effect waves-light primary-color">{{ 'READ_MORE' | translate }}</button></a></div>
    </div>
</div>

<!--
<div class="row" id="more-slider">
    <div class="container">
        <div class="divider"></div>
        <h3>More about Abalobi</h3>

        <div class="slider">
            <ul class="slides">
                <li id="instagram-slide">
                    <div class="caption center-align">
                        <h3>Instagram</h3>
                        <h5 class="light grey-text text-lighten-3">Follow us on Instagram</h5>
                        <a href="https://www.instagram.com/abalobi_app/" class="btn waves-effect white grey-text darken-text-2">view</a>
                    </div>
                </li>
                <li id="twitter-slide">
                    <div class="caption center-align">
                        <h3>Twitter</h3>
                        <h5 class="light grey-text text-lighten-3">Follow us on Twitter</h5>
                        <a href="https://twitter.com/abalobi_app?lang=en" class="btn waves-effect white grey-text darken-text-2">view</a>
                        <h5 class="light grey-text text-lighten-3"><a href="https://twitter.com/hashtag/fromhooktocook">#fromhooktocook</a> <a href="https://twitter.com/hashtag/whofishesmatters">#whofishesmatters</a> <a href="https://twitter.com/hashtag/fishwithastory">#fishwithastory</a></h5>
                    </div>
                </li>
                <li id="ted-slide">
                    <div class="caption left-align">
                        <h3>TEDxCapeTownSalon</h3>
                        <h5 class="light grey-text text-lighten-3">Dr Serge Raemaekers explains the Abalobi mission.</h5>
                        <a href="https://www.youtube.com/watch?v=dArh0EfNbwY" class="btn waves-effect white grey-text darken-text-2">watch</a>
                    </div>
                </li>
                <li id="facebook-slide">
                    <div class="caption right-align">
                        <h3>Facebook</h3>
                        <h5 class="light grey-text text-lighten-3">Like us on Facebook</h5>
                        <a href="https://www.facebook.com/abalobi.org/" class="btn waves-effect white grey-text darken-text-2">view</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
-->


<!-- FOOTER -->
<footer class="page-footer">
    <div class="container">
        <div class="row">
            <div class="col l6 s12">
                <!-- TODO MT refactoring - Are these contact details appropriate for different tenants -->
                <h5 class="white-text">{{ 'CONTACT_US' | translate }}</h5>
                <ul>
                    <li><a target="_blank" href="mailto:hello@abalobi.org" class="grey-text text-lighten-4"><i class="fas fa-envelope-square"></i><span class="social-text">{{ 'CONTACT_URL' | translate }}</span></a></li>
                    <li><a target="_blank" href="http://www.abalobi.org" class="grey-text text-lighten-4"><i class="fas fa-globe"></i><span class="social-text">www.abalobi.org</span></a></li>
                    <li><a target="_blank" href="https://www.facebook.com/abalobi.org/" class="grey-text text-lighten-4"><i class="fab fa-facebook-square"></i><span class="social-text">abalobi.org</span></a></li>
                    <li><a target="_blank" href="https://twitter.com/abalobi_app?lang=en" class="grey-text text-lighten-4"><i class="fab fa-twitter-square"></i><span class="social-text">&#64;abalobi_app</span></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/abalobi_app/" class="grey-text text-lighten-4"><i class="fab fa-instagram"></i><span class="social-text">&#64;abalobi_app</span></a></li>
                </ul>
            </div>
            <div class="col l4 offset-l2 s12">

            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            © {{ currYear }} {{ 'APP_VERSION' | translate }} {{ version }}
        </div>
    </div>
</footer>
