
<!-- Loading spinner -->
<div
    *ngIf="cCatch === null && cBatch === null && showError === false && noStoryContent === false && noFisher === false">
    <app-gooey-loader></app-gooey-loader>
</div>

<!-- Error message -->
<div class="error-msg-container" *ngIf="showError === true">
    <div class="error-msg-child-container center-align">
        <h2 class="error-msg">
            {{ 'CATCH_ONE_GOT_AWAY' | translate }}
        </h2>
        <h5 class="error-msg">{{ 'CATCH_TAG_NOT_REGISTERED' | translate }}</h5>
        <a routerLink="">
            <button id="return-home-button" class="btn waves-effect waves-light blue darken-3">{{ 'GO_HOME' | translate }}</button>
        </a>
    </div>
</div>

<!-- No Story content message -->
<div class="error-msg-container" *ngIf="noStoryContent === true">
    <div class="error-msg-child-container center-align">
        <h2 class="error-msg">
            {{ 'CATCH_NO_STORY_ERROR_MSG' | translate }}
        </h2>
        <h5 class="error-msg">
            {{ 'CATCH_NO_STORY_ERROR_MSG_NOTIFIED' | translate }}
        </h5>
        <a routerLink="">
            <button id="return-home-button" class="btn waves-effect waves-light blue darken-3">
                {{ 'GO_HOME' | translate }}
            </button>
        </a>
    </div>
</div>

<!-- Static QR not found message -->
<div class="error-msg-container" *ngIf="qrNotFound === true">
    <div class="error-msg-child-container center-align">
        <h2 class="error-msg">
            {{ 'CATCH_QR_NOT_FOUND' | translate }}
        </h2>
        <a routerLink="">
            <button id="return-home-button" class="btn waves-effect waves-light blue darken-3">{{ 'GO_HOME' | translate }}</button>
        </a>
    </div>
</div>

<ng-container *ngIf="cBatch != null && showError !== true && noStoryContent !== true">

    <!-- Social sharing fixed button -->
    <div class="fixed-action-btn {{this.isSmallScreen ? 'click-to-toggle' : ''}}">
        <a target="_blank" class="btn-floating btn-large {{this.isSmallScreen ? '' : 'tooltipped'}}"
            data-position="left" data-delay="50" data-tooltip="Share this page">
            <img style="width: 25px; margin-top: 7px;" src="assets/icons/h2c-icon.svg">
        </a>
        <ul>
            <li>
                <!-- TODO MT refactoring needed - Need to check if all these social media links should still point to a global account (instead of tenant-specific one) -->
                <a target="_blank" class="btn-floating twitter-blue"
                    [href]="'https://twitter.com/share?url=http%3A%2F%2Fhooktocook.abalobi.info%2Fcatch%2F' + catchId + '&amp;text=I%20just%20traced%20the%20story%20behind%20my%20seafood%20all%20the%20way%20back%20to%20the%20small%2Dscale%20fisher%20who%20caught%20it%21%20See%20it%20here%3A&amp;hashtags=fromhooktocook,abalobi'">
                    <i class="fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a target="_blank" class="btn-floating instagram-pink" href="https://www.instagram.com/abalobi_app/">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            <li>
                <a target="_blank" class="btn-floating facebook-blue"
                    [href]="'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fhooktocook.abalobi.info%2Fcatch%2F'+ catchId +'&amp;src=sdkpreparse'">
                    <i class="fab fa-facebook-f"></i>
                </a>
            </li>
        </ul>
    </div>

    <!-- HEADER -->
    <nav class="primary-color">
        <div class="container">
            <img class="hide-on-med-and-down" id="navbar-logo"
                src="https://res.cloudinary.com/techairos/image/upload/c_scale,w_64/v1521026226/navbar_kgbo7e.png">
            <a class="left brand-logo" routerLink="">
                {{ 'ABALOBI' | translate }}
            </a>
            <ul class="right">
                <li>
                    <a target="_blank" href="https://www.facebook.com/abalobi.org/">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://twitter.com/abalobi_app?lang=en">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/abalobi_app/">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
            </ul>
        </div>
    </nav>

    <div id="listing-warning" *ngIf="show_listing_warning"
        class="row center-align red lighten-3 modal-trigger clickable" data-target="oldListingModal">
        <div class="container">
            <strong>{{ 'CATCH_STORY_PRIOR_BATCH' | translate }}
                <a><i class="material-icons right">more_vert</i></a>
            </strong>
        </div>
    </div>

    <div class="row ex secondary-color" id="header">
        <div class="container">
            <!-- Large header for large screens -->
            <div *ngIf="!checkIfMedScreenorSmaller()">
                <!-- <h2>Welcome to <a target="_blank" href="https://www.instagram.com/explore/tags/fromhooktocook">#fromhooktocook</a> by ABALOBI</h2> -->
                <h2>{{ 'FISH_WITH_A_STORY' | translate }}</h2>
                <h6>{{ 'POWERED_BY_UPPERCASE' | translate }}</h6>
                <div class="row">
                    <div class="col s2 l2 m2"><img class="circular-title-pic"
                            src="https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png" />
                    </div>
                    <div class="col s10 l10 m10">
                        <h4 class="subtitle-text">{{typewriterDisplay}}<span class="cursor"></span></h4>
                    </div>
                </div>
            </div>
            <!-- Smaller header for medium and small screens -->
            <div *ngIf="checkIfMedScreenorSmaller()">
                <!-- <h4>Welcome to <a target="_blank" href="https://www.instagram.com/explore/tags/fromhooktocook">#fromhooktocook</a> by ABALOBI</h4> -->
                <h2 class="mobile">{{ 'FISH_WITH_A_STORY' | translate }}</h2>
                <h6>{{ 'POWERED_BY_UPPERCASE' | translate }}</h6>
                <div class="row">
                    <div class="col s3 l3 m3"><img class="circular-title-pic-small"
                            src="https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png" />
                    </div>
                    <div class="col s9 l9 m9">
                        <p class="subtitle-text-small">{{ typewriterDisplay }}<span class="cursor"></span></p>
                    </div>
                </div>
            </div>
            <!-- <p>Thank you for choosing <a target="_blank" href="https://www.instagram.com/explore/tags/fishwithastory">#fishwithastory</a>, thereby supporting small-scale fishers' journey towards sustainability in their communities and the ocean they depend on.<span *ngIf="batch_buyer_name != null && batch_buyer_name != undefined"> You are at {{batch_buyer_name}} <a class="modal-trigger clickable" data-target="incorrectEstablishmentModal"><i class="material-icons right">more_vert</i></a></span>...</p> -->
            <p>{{ 'THANK_YOU_MESSAGE' | translate }}</p>
        </div>
    </div>


    <!-- PROMO VIDEO -->
    <div id="serge-div">
        <div class="rowx">
            <div class="containerx">
                <div class="col s12 m12 l12">
                    <div class="video-container x">
                        <iframe width="100%" height="100%"
                            [src]="promoVideo"
                            frameborder="0" allowfullscreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- FISHERS -->
    <div class="row" id="fishers">
        <div class="container">

            <div [ngClass]="checkIfCentered()">
                <h5>{{ 'WHO' | translate }}</h5>
                <div class="divider"></div>
            </div>

            <div class="row">
                <div class="col s12" [ngClass]="getColumnSpread(cBatch.fishers.length, i)"
                    *ngFor="let fisher of cBatch.fishers, let i = index">
                    <div class="card clickable">
                        <div class="card-image waves-effect waves-block waves-light">
                            <img
                                class="activator"
                                src="{{ fisher.image_url__c }}"/>
                        </div>
                        <div class="card-content activator">
                            <span class="card-title activator grey-text text-darken-4">
                                <i *ngIf="fisher.h2c_blurb__c != null && fisher.h2c_blurb__c !== undefined"
                                    class="material-icons fade-in-later right">more_vert</i>
                                {{ fisher.FirstName__c + " " + fisher.LastName__c }}
                            </span>
                            <p appHtmlFormatter
                                [innerHTML]='"<div class=" + "\"rich-text-truncate activator\"" + ">" + (fisher.h2c_blurb__c || "") + "</div>"'>
                            </p>
                        </div>
                        <div *ngIf="fisher.h2c_blurb__c != null && fisher.h2c_blurb__c !== undefined"
                            class="card-reveal">
                            <span class="card-title grey-text text-darken-4">
                                {{fisher.FirstName__c}}'s {{ 'STORY' | translate }}
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                            </span>
                            <p appHtmlFormatter [innerHTML]='"<div class=" + "rich-text" + ">" + fisher.h2c_blurb__c + "</div>"'>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- POST HARVEST -->
    <div class="row" id="postHarvest">
        <div class="container">

            <div [ngClass]="checkIfCentered()">
                <h5>{{ 'POST_HARVEST' | translate }}</h5>
                <div class="divider"></div>
            </div>

            <div class="row">
                <div class="col s12" [ngClass]="getColumnSpread(qcCommunitiesInfo.length, i)"
                    *ngFor="let community of qcCommunitiesInfo; let i = index">
                    <div class="card clickable">
                        <div class="card-image waves-effect waves-block waves-light">
                            <!-- TODO MT refactoring needed - Are cloudinary references OK for MT? -->
                            <img class="activator"
                                src="{{
                                    community.imageUrl != null
                                        ? community.imageUrl
                                        : 'https://res.cloudinary.com/techairos/image/upload/v1519804582/535106-user_512x512_x9q5cr.png'
                                    }}"
                            />
                        </div>
                        <div class="card-content activator">
                            <span class="card-title activator grey-text text-darken-4">
                                <i *ngIf="community.info != null && community.info !== undefined"
                                    class="material-icons fade-in-later right">more_vert</i>
                                {{ community.name }}
                            </span>
                            <p appHtmlFormatter
                                [innerHTML]='"<div class=\"rich-text-truncate activator\">" + (community.info || "") + "</div>"'>
                            </p>
                        </div>
                        <div *ngIf="community.info != null && community.info !== undefined"
                            class="card-reveal">
                            <span class="card-title grey-text text-darken-4">
                                {{ community.name }}'s {{ 'QC_TEAM' | translate }}
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                            </span>
                            <ul>
                                <div *ngFor="let person of community.qcTeam">
                                    <li>{{ person }}</li>
                                </div>
                            </ul>
                            <p appHtmlFormatter
                            [innerHTML]='"<div class=\"rich-text-truncate activator\">" + (community.info || "") + "</div>"'>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- QUOTE 1 -->
    <div *ngIf="quotesArray.length >= 1">
        <!-- if there is at least one quote -->
        <div id="quote1" class="row quote-row quote1-color">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div *ngIf="!!quotesArray[0].image" class="col l12 m12 s12 circular-quote-container-center">
                        <img class="circular-quote-pic"
                            [src]="quotesArray[0].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                </div>
                <div>
                    <div *ngIf="!!quotesArray[0].image"
                        class="col xl2 m3 hide-on-small-only circular-quote-container-center">
                        <img class="circular-quote-pic"
                            [src]="quotesArray[0].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p appHtmlFormatter
                                [innerHtml]="(!!quotesArray[0].name ? '' : '')
                                + (quotesArray[0].quote)
                                + (!!quotesArray[0].name ? '' : '')">
                            </p>
                            <strong *ngIf="!!quotesArray[0].name"><small>- {{quotesArray[0].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- div *ngIf="quotesArray.length < 1">                <div class="divider"></div></div -->

    <!-- SPECIES -->
    <!-- If no quote above to differentiate species from fishers, make species primary color -->
    <div class="row" id="species" class="zero-margin extra-v-padding">
        <div class="container">

            <div [ngClass]="checkIfCentered()">
                <h5>{{ 'WHAT' | translate }}</h5>
                <div class="divider"></div>
                <p>{{ 'CATCH_HARVEST_INCLUDES' | translate }}</p>
            </div>

            <div class="row zero-margin">
                <!-- Loop through species -->
                <div class="col s12"  *ngFor="let species of cBatch.species, let i = index" [ngClass]="getColumnSpread(cBatch.species.length, i)">
                    <div class="card clickable">
                        <div class="card-image waves-effect waves-block waves-light">
                            <img class="activator species-image"
                                src="{{species.image_url__c ||
                          'https://res.cloudinary.com/techairos/image/upload/v1519804582/535106-user_512x512_x9q5cr.png'}}" />
                        </div>
                        <div class="card-content activator">
                            <span class="card-title activator grey-text text-darken-4">
                                <i class="material-icons right">more_vert</i>
                                {{species.name_eng__c}}
                            </span>
                            <p appHtmlFormatter
                                [innerHtml]='"<div class=" + "\"rich-text-truncate activator\"" + ">" + (species.Ablb_Species_Writeup__c || "") + "</div>"'>
                            </p>
                        </div>
                        <div class="card-reveal">
                            <span class="card-title grey-text text-darken-4">
                                {{ 'CATCH_ABOUT_SPECIES' | translate }} {{species.name_eng__c}}
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                            </span>
                            <p><strong>{{ 'SPECIES_LOCAL_NAME' | translate }} </strong>{{species.name_eng__c}}</p>
                            <p appHtmlFormatter
                                [innerHTML]='"<div class=" + "rich-text" + ">" + (species.Ablb_Species_Writeup__c || "No description") + "</div>"'>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!--
                only show the "why is more than one species displayed here prompt?" if more than one species is shown
                if more than one species is shown -> sassi logo is always on the right
                otherwise it goes on the center for medium and large screens

                HIDE LINK TO MODAL
                Issue: 3582
                On the H2C website, clicking on “why is more than one species displayed here” brings up a blank modal with an odd yellow border.
                Please remove this link (and the text) from H2C for now.
             -->

            <!-- <div *ngIf="cBatch && cBatch.species.length >= 2" class="row zero-side-margin">
                <a class="modal-trigger clickable" data-target="whyMoreSpeciesModal">
                    <i class="far fa-question-circle"></i>
                    <small class="small-small" id="why-is-more-species-shown">{{ 'CATCH_WHY_MORE_THAN_ONE' | translate }}</small>
                </a>
            </div> -->

            <!--<div class="row sassi-container-small zero-side-margin">-->
            <!--<a target="_blank" data-target="sendSMSModal"  class="sassi-link">-->
            <!--Working with &nbsp;<img class="sassi-logo" src="https://res.cloudinary.com/techairos/image/upload/v1522915596/small_t6k4mj.png">-->
            <!--</a>-->
            <!--<button data-target="workingWithSASSI" class="btn waves-effect secondary-color darken-text-2 modal-trigger clickable sms-button">Working with <img class="sassi-logo" src="https://res.cloudinary.com/techairos/image/upload/v1522915596/small_t6k4mj.png"></button>-->
            <!--<a class="modal-trigger clickable" data-target="workingWithSASSI"><small class="small-small" id="working-with-sassi">Working with <img class="sassi-logo" src="https://res.cloudinary.com/techairos/image/upload/v1522915596/small_t6k4mj.png"></small></a>-->
            <!--</div>-->

        </div>
    </div>

    <!-- QUOTE 2 -->
    <div *ngIf="quotesArray.length >= 2">
        <!-- are at least 2 quotes -->
        <div id="quote2" class="row quote-row quote2-color zero-margin">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div *ngIf="!!quotesArray[1].image" class="col l12 m12 s12 circular-quote-container-center"><img
                            class="circular-quote-pic"
                            [src]="quotesArray[1].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                </div>
                <div>
                    <div *ngIf="!!quotesArray[1].image"
                        class="col xl2 m3 hide-on-small-only circular-quote-container-center"><img
                            class="circular-quote-pic"
                            [src]="quotesArray[1].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p appHtmlFormatter
                                [innerHtml]="(!!quotesArray[1].name ? '' : '')
                                + (quotesArray[1].quote)
                                + (!!quotesArray[1].name ? '' : '')">
                            </p>
                            <strong *ngIf="!!quotesArray[1].name"><small>- {{quotesArray[1].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--div *ngIf="quotesArray.length < 2"><div class="row quote-row-no-text secondary-color"></div></div-->

    <!-- COMMUNITY
    Hide overflow (map image may overflow - but thats nice if the community description is long)
    -->
    <!--div class="container extra-v-padding">

        <div [ngClass]="checkIfCentered()">
            <h5>Where?</h5>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col s12" [ngClass]="getColumnSpread(cBatch.communities.length, i)" *ngFor="let community of cBatch.communities, let i = index">
                <div class="card clickable">
                    <div class="card-image">
                        <img class="activator" src="{{'https://maps.googleapis.com/maps/api/staticmap?center=' + community.gps_lat__c + ',' + community.gps_lon__c + '&size=296x192&zoom=8&markers=color:0xffffff%7C%7C'  + community.gps_lat__c + ',' + community.gps_lon__c +  '&maptype=hybrid&scale=2&format=jpg&key=AIzaSyCLpuJl8e3FDinpy7izUDRsPA-luJCpHew'}}"/>
                    </div>
                    <div class="card-content activator">
                                <span class="card-title grey-text text-darken-4 activator">
                                    <i class="material-icons right">more_vert</i>
                                    {{community.name_eng__c}}
                                </span>
                        <p [innerHTML]='"<div class=" + "\"rich-text-truncate activator\"" + ">" + (community.comments__c || "") + "</div>"'></p>
                    </div>
                    <div class="card-reveal">
                            <span class="card-title grey-text text-darken-4">
                                About {{community.name_eng__c}}
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                            </span>
                        <p><strong>English name: </strong>{{community.name_eng__c}}</p>
                        <p><strong>Location: </strong><a target="_blank" [href]='"https://www.google.com/maps/search/?api=1&query=" + community.gps_lat__c + "," + community.gps_lon__c'>View in Google Maps</a></p>
                        <p [innerHTML]='"<div class=" + "rich-text" + ">" + (community.comments__c || "No description") + "</div>"'></p>
                    </div>
                </div>
            </div>
        </div>
    </div-->

    <!--     COMMUNITY V2     -->
    <div class="container extra-v-padding" id="community">

        <div [ngClass]="checkIfCentered()">
            <h5>{{ 'CATCH_WHERE_SUBTITLE' | translate }}</h5>
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col s12" [ngClass]="getColumnSpread(cBatch && cBatch.communities.length, i)"
                *ngFor="let community of cBatch?.communities, let i = index">
                <div class="card clickable">
                    <div class="card-image waves-effect waves-block waves-light">
                        <img class="activator" src="{{community.image_url__c}}" />
                    </div>
                    <div class="card-content activator">
                        <span class="card-title activator grey-text text-darken-4">
                            <i *ngIf="community.comments__c != null && community.comments__c !== undefined"
                                class="material-icons fade-in-later right">more_vert</i>
                            {{community.name_eng__c}}
                        </span>
                        <p appHtmlFormatter
                            [innerHTML]='"<div class=" + "\"rich-text-truncate activator\"" + ">" + (community.comments__c || "") + "</div>"'>
                        </p>
                    </div>
                    <div *ngIf="community.comments__c != null && community.comments__c !== undefined"
                        class="card-reveal">
                        <span class="card-title grey-text text-darken-4">
                            {{ 'CATCH_ABOUT_COMMUNITY' | translate }} {{community.name_eng__c}}
                            <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                        </span>
                        <p appHtmlFormatter
                            [innerHTML]='"<div class=" + "rich-text" + ">" + community.comments__c + "</div>"'></p>
                    </div>
                </div>
            </div>
            <div class="col s12" *ngIf="cBatch && cBatch.communities && cBatch.communities.length >= 1">
                <!-- If there is at least 1 community -->
                <div style="width: 100%;">
                    <google-map [options]="mapOptions" width="100%" [height]="300">
                        <map-marker *ngFor="let community of cBatch.communities"
                        [position]="{ lat: community.gps_lat__c, lng: community.gps_lon__c }"
                        [options]="markerOptions"></map-marker>
                    </google-map>
                </div>

            </div>
        </div>
    </div>

    <!-- QUOTE 3 -->
    <div *ngIf="quotesArray.length >= 3">
        <!-- if there are at least 3 quotes -->
        <div id="quote3" class="row quote-row quote3-color">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div *ngIf="!!quotesArray[2].image" class="col l12 m12 s12 circular-quote-container-center"><img
                            class="circular-quote-pic"
                            [src]="quotesArray[2].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                </div>
                <div>
                    <div *ngIf="!!quotesArray[2].image"
                        class="col xl2 m3 hide-on-small-only circular-quote-container-center"><img
                            class="circular-quote-pic"
                            [src]="quotesArray[2].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p appHtmlFormatter
                                [innerHtml]="(!!quotesArray[2].name ? '' : '')
                                + (quotesArray[2].quote)
                                + (!!quotesArray[2].name ? '' : '')">
                            </p>
                            <strong *ngIf="!!quotesArray[2].name"><small>- {{quotesArray[2].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- BOAT -->
    <div class="row zero-margin extra-v-padding" id="boat" *ngIf="cBatch.how.length !== 0">
        <div class="container">

            <div [ngClass]="checkIfCentered()">
                <h5>{{ 'CATCH_HOW_SUBTITLE' | translate }}</h5>
                <div class="divider"></div>
            </div>

            <div class="row zero-margin">

                <!-- Loop through boats -->
                <div class="col s12" [ngClass]="getColumnSpread(cBatch.fishers.length, i)"
                    *ngFor="let how of cBatch.how, let i = index">
                    <div class="card clickable">
                        <div *ngIf="how.boat_imgUrl" class="card-image waves-effect waves-block waves-light">
                            <img class="activator {{how.boat_description ? '' : 'rounded'}}" [src]="how.boat_imgUrl" />
                        </div>
                        <div *ngIf="how.boat_description || how.weather || how.num_crew || how.method"
                            class="card-content activator">
                            <span class="card-title activator grey-text text-darken-4">
                                <i *ngIf="how.boat_description || how.weather || how.num_crew || how.method"
                                    class="material-icons right">more_vert</i>
                                {{ cBatch.fishers[i].FirstName__c + " " + cBatch.fishers[i].LastName__c }}
                            </span>

                            <p *ngIf="how.boat_description" appHtmlFormatter
                                [innerHTML]='"<div class=\"rich-text-truncate activator\">" + how.boat_description + "</div>"'>
                            </p>
                            <!-- CHRIS: TOD): InnerHtml "Number of crew"-->
                            <p *ngIf="!how.boat_description" appHtmlFormatter
                                [innerHTML]='"<div class=\"rich-text-truncate activator\"><strong>Number of crew:</strong> " +  how.num_crew + ", <strong>Method:</strong> " + humanify(how.method) + ", <strong>Weather:</strong> " + humanify(how.weather) + ", <strong>Sea conditions:</strong> " + humanify(how.sea_condition) + "</div>"'>
                            </p>
                        </div>
                        <div class="card-reveal"
                            *ngIf="how.boat_description || how.weather || how.num_crew || how.method">
                            <span class="card-title grey-text text-darken-4">
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                            </span>
                            <span class="rich-text">
                                <p appHtmlFormatter [innerHTML]='how.boat_description'></p>
                                <!-- CHRIS: TODO: InnerHtml "Number of crew:"-->
                                <p *ngIf="!!how.num_crew"
                                   ><strong>{{ 'NUMBER_OF_CREW' | translate }} </strong> {{ how.num_crew }}</p>
                                <p *ngIf="!!how.method"><strong>{{ 'CATCH_METHOD' | translate }} </strong><span
                                        *ngFor="let sub_method of how.method.split(', '); let last = last"><a
                                            (click)="methodInfoClicked(sub_method)" class="modal-trigger clickable"
                                            data-target="methodInfoModal" [innerHTML]="humanify(sub_method)"></a>{{last
                                        ? "." : ", "}}</span>
                                <p *ngIf="!!how.weather" appHtmlFormatter
                                    [innerHTML]='"<strong>Weather: </strong>" + humanify(how.weather)'></p>
                                <p *ngIf="!!how.sea_condition" appHtmlFormatter
                                    [innerHTML]='"<strong>Sea conditions: </strong>" + humanify(how.sea_condition)'></p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- QUOTE 4 -->
    <div *ngIf="quotesArray.length >= 4">
        <!-- if there are at least 4 quotes -->
        <div id="quote4" class="row quote-row quote1-color">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div *ngIf="!!quotesArray[3].image" class="col l12 m12 s12 circular-quote-container-center"><img
                            class="circular-quote-pic"
                            [src]="quotesArray[3].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                </div>
                <div>
                    <div *ngIf="!!quotesArray[3].image"
                        class="col xl2 m3 hide-on-small-only circular-quote-container-center"><img
                            class="circular-quote-pic" [src]="quotesArray[3].image" /></div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p appHtmlFormatter
                                [innerHtml]="(!!quotesArray[3].name ? '' : '')
                                + (quotesArray[3].quote)
                                + (!!quotesArray[3].name ? '' : '')">
                            </p>
                            <strong *ngIf="!!quotesArray[3].name"><small>- {{quotesArray[3].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- SMS -->
    <div class="row extra-v-padding">
        <div class="container">
            <!-- check if at least one smsPrompt is defined -->
            <div *ngIf="containsNotOnlyNulls(smsPromptsArray)">
                <div [ngClass]="checkIfCentered()">
                    <h5>{{ 'CATCH_SMS_MESSAGE_FROM' | translate }}</h5>
                </div>
            </div>
            <div *ngFor="let fisher of cBatch.fishers; let i = index">
                <div *ngIf="smsPromptsArray[i] != null">
                    <div class="divider"></div>
                    <div class="quote-row">
                        <blockquote class="sms-blockquote">
                            <p>{{!!smsPromptsArray[i].name ? '"' :
                                ''}}{{smsPromptsArray[i].quote}}{{!!smsPromptsArray[i].name ? '"' : ''}}
                            </p>
                            <strong *ngIf="!!smsPromptsArray[i].name"><small>-
                                    {{smsPromptsArray[i].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
            <div *ngIf="containsNotOnlyNulls(smsPromptsArray)" class="divider"></div>
        </div>
        <div class="center-align" *ngIf="tenantService.tenantCode === 'abarsa' && cBatch">

            <button *ngIf="cBatch.fishers.length <= 2 && cBatch.fishers.length > 0" data-target="sendSMSModal"
                class="btn waves-effect secondary-color modal-trigger clickable sms-button">
                <i class="far fa-envelope sms-icon"></i>
                Connect with {{cBatch.fishers[0].FirstName__c}}
                <span *ngIf="cBatch.fishers[1] != null">
                    {{ 'CATCH_AND' | translate }} {{cBatch.fishers[1].FirstName__c}}
                </span>
            </button>
            <button
                *ngIf="cBatch.fishers.length > 2  && cBatch.fishers.length > 0"
                data-target="sendSMSModal"
                class="btn waves-effect secondary-color darken-text-2 modal-trigger clickable sms-button">
                <i class="far fa-envelope sms-icon"></i>
                {{ 'CATCH_CONNECT_WITH_FISHERS_BTN' | translate }}
            </button>
            <div class="container">
                <div *ngIf="smsPromptsArray.length === 0" class="divider"></div>
            </div>
        </div>
    </div>


    <!-- QUOTE 5 -->
    <!-- TODO: need to find out what's going on here -->
    <!-- <div *ngIf="quotesArray.length >= 5">
        <div id="quote5" class="row quote-row quote2-color">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div *ngIf="!!quotesArray[4].image" class="col l12 m12 s12 circular-quote-container-center">
                        <img class="circular-quote-pic"
                            [src]="quotesArray[4].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                </div>
                <div>
                    <div *ngIf="!!quotesArray[4].image"
                        class="col xl2 m3 hide-on-small-only circular-quote-container-center">
                        <img class="circular-quote-pic"
                            [src]="quotesArray[4].image || 'https://res.cloudinary.com/techairos/image/upload/v1522142341/circular_vpxxes.png'" />
                    </div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p>{{!!quotesArray[4].name ? '' : ''}}{{quotesArray[4].quote}}{{!!quotesArray[4].name ? '' : ''}}
                            </p>
                            <strong *ngIf="!!quotesArray[4].name"><small>- {{quotesArray[4].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- VIDEO 1 -->
    <div class="row" *ngIf="videosArray.length >= 1">
        <!-- if there is at least one video -->
        <div class="container">
            <div class="col s12 m6 l6">
                <div class="video-container">
                    <iframe width="853" height="480" [src]="videosArray[0].url | safe" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
            <div *ngIf="videosArray[0].intro != null && videosArray[0].intro !== undefined" class="col s12 m6 l6">
                <div class="video-container-text">
                    <p class="rich-text" appHtmlFormatter [innerHTML]="videosArray[0].intro"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- VIDEO 2 -->
    <div class="row" *ngIf="videosArray.length >= 2">
        <!-- if there are at least two videos -->
        <div class="container">
            <div class="divider"></div>
            <div class="col s12 m6 l6">
                <div class="video-container">
                    <iframe width="853" height="480" [src]="videosArray[1].url | safe" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
            <div *ngIf="videosArray[1].intro != null" class="col s12 m6 l6">
                <div class="video-container-text">
                    <p class="rich-text" appHtmlFormatter [innerHTML]="videosArray[1].intro"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- QUOTE 6 -->
    <!-- TODO: need to find out what's going on here -->
    <!-- <div *ngIf="quotesArray.length >= 6">
        <div id="quote6" class="row quote-row quote3-color">
            <div class="container">
                <div class="row hide-on-med-and-up">
                    <div class="col l12 m12 s12 circular-quote-container-center">
                        <img class="circular-quote-pic" [src]="getImage(quotesArray[5].image)" />
                    </div>
                </div>
                <div>
                    <div class="col xl2 m3 hide-on-small-only circular-quote-container-center">
                        <img class="circular-quote-pic" [src]="getImage(quotesArray[5].image)" />
                    </div>
                    <div class="col xl10 m9 s12">
                        <blockquote class="quote-blockquote">
                            <p>{{!!quotesArray[5].name ? '' : ''}}{{quotesArray[5].quote}}{{!!quotesArray[5].name ? '' : ''}}</p>
                            <strong *ngIf="!!quotesArray[5].name"><small>- {{quotesArray[5].name}}</small></strong>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- CULTURAL PERSPECTIVES -->
    <div *ngIf="culturalPerspectivesArray.length > 0">
        <div class="container extra-v-padding">
            <div [ngClass]="checkIfCentered()">
                <h5>{{ 'CATCH_CULTURAL_PERSPECTIVES_TITLE' | translate }}</h5>
            </div>
            <div class="divider"></div>

            <!-- karin new section-->
            <div class="row zero-margin">

                <div class="col l6 m12 s12" *ngFor="let perspective of culturalPerspectivesArray, let i = index">
                    <div class="card clickable">

                        <div class="card-content">
                            <div class="row">
                                <!-- loop through all the images associated with this fisherman's cultural perspective -->
                                <div *ngFor="let image of perspective['images']; let j = index">
                                    <div [hidden]="showSlide(i, j)" class="center-align">
                                        <img class="materialboxed mb-center" [src]="image">
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="perspective['images'].length > 1" class="row layout">
                                <div class="browseImg">
                                    <button class="waves-effect waves-light btn" (click)="prevSlide(i)">
                                        <i class="material-icons fade-in-later">chevron_left</i>
                                    </button>
                                    <button class="waves-effect waves-light btn" (click)="nextSlide(i)">
                                        <i class="material-icons fade-in-later">chevron_right</i>
                                    </button>
                                </div>
                                <span class="card-title activator grey-text text-darken-4">
                                    <i class="material-icons right">more_vert</i>
                                </span>
                            </div>

                            <div class="layout">
                                <p class="shortBlurb" appHtmlFormatter
                                    [innerHTML]='"<div class=" + "\"rich-text-truncate cls activator\"" + ">" + (perspective.blurb || "") + "</div>"'>
                                </p>
                            </div>

                        </div>

                        <div class="card-reveal">
                            <span class="card-title grey-text text-darken-4">
                                <i class="material-icons right">{{ "CLOSE" | translate }}</i>
                                <div class="video-container-text">
                                    <p class="cultural-text" appHtmlFormatter [innerHTML]="perspective['blurb']"></p>
                                </div>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end my test-->

        <!-- loop through the fishermen's cultural perspectives -->
        <!-- <div class="row zero-margin" *ngFor="let perspective of culturalPerspectivesArray, let i = index">

            <div class="container center-align">

                <div class="col s12 m6 l6">
                    <div class="card">
                        <div class="card-content">
                            <div class="row">
                                 loop through all the images associated with this fisherman's cultural perspective
                                <div *ngFor="let image of perspective['images']; let j = index">
                                    <div [hidden]="showSlide(i, j)" class="center-align"><img
                                            class="materialboxed mb-center" [src]="image"></div>
                                </div>
                            </div>

                            <div *ngIf="perspective['images'].length > 1" class="row">
                                <button class="waves-effect waves-light btn" (click)="prevSlide(i)"><i
                                        class="material-icons fade-in-later">chevron_left</i></button>
                                <button class="waves-effect waves-light btn" (click)="nextSlide(i)"><i
                                        class="material-icons fade-in-later">chevron_right</i></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="container">
                <div class="row">
                    <div class="col s12 m6 l6">
                        <div class="video-container-text">
                            <p class="rich-text" appHtmlFormatter [innerHTML]="perspective['blurb']"></p>
                        </div>
                    </div>
                </div>
            </div>

        </div> -->

    </div>

    <!-- SHARING SECTION -->
    <div id="sharing-section" class="row quote-row primary-color">
        <div class="container center-align">
            <p>{{ 'SHARE_THIS' | translate }} <a target="_blank"
                    href="https://www.instagram.com/explore/tags/fishwithastory">{{ 'HASHTAG' | translate}}</a> {{
                'USE_HASHTAGS_BELOW' | translate }}</p>
            <p>
                <a target="_blank" class="btn-floating twitter-blue"
                    [href]="'https://twitter.com/share?url=http%3A%2F%2Fhooktocook.abalobi.info%2Fcatch%2F' + catchId + '&amp;text=I%20just%20traced%20the%20story%20behind%20my%20seafood%20all%20the%20way%20back%20to%20the%20small%2Dscale%20fisher%20who%20caught%20it%21%20See%20it%20here%3A&amp;hashtags=fromhooktocook,abalobi'">
                    <i class="fab fa-twitter"></i>
                </a>

                <a target="_blank" class="btn-floating instagram-pink" href="https://www.instagram.com/abalobi_app/">
                    <i class="fab fa-instagram"></i>
                </a>

                <a target="_blank" class="btn-floating facebook-blue"
                    [href]="'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fhooktocook.abalobi.info%2Fcatch%2F'+ catchId +'&amp;src=sdkpreparse'">
                    <i class="fab fa-facebook-f"></i>
                </a>

            </p>

            <p class="sharing-tags">
                <a target="_blank" href="https://www.instagram.com/explore/tags/abalobi">
                    {{ 'SOCIAL_TAG' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/fishwithastory">
                    {{ 'SOCIAL_INSTA_1' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/fromhooktocook">
                    {{ 'SOCIAL_INSTA_2' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/rethinkingsustainability">
                    {{ 'SOCIAL_INSTA_3' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/smallscalefisheries">
                    {{ 'SOCIAL_INSTA_4' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/fairfish">
                    {{ 'SOCIAL_INSTA_5' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/traceablefish">
                    {{ 'SOCIAL_INSTA_6' | translate }}
                </a>
                <a target="_blank" href="https://www.instagram.com/explore/tags/techforgood">
                    {{ 'SOCIAL_INSTA_7' | translate }}
                </a>
            </p>

        </div>
    </div>

    <!-- KEEN TO KNOW MORE -->
    <div class="row extra-v-padding">
        <div class="container">
            <div class="col s12 m12 l12">
                <div [ngClass]="checkIfCentered()">
                    <h5>{{ 'KNOW_MORE' | translate }}</h5>
                </div>
                <div class="divider"></div>
                <p class="center space">Visit <a href="https://www.fishwithastory.org">www.fishwithastory.org</a></p>
            </div>
            <div class="col s12 m12 l12">
                <div class="center-align">
                    <img id="brochure-img" src="assets/images/fishwithastory1500x.png">
                </div>
            </div>
        </div>
    </div>


    <!-- SERGE TED TALK -->
    <!-- <div id="serge-div">
        <div class="row">
            <div class="container">
                <div class="divider"></div>
                <div class="col s12 m6 l6">
                    <div class="video-container">
                        <iframe width="853" height="480" src="https://www.youtube.com/embed/dArh0EfNbwY?ecver=1"
                            frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col s12 m6 l6 hide-on-med-and-down">
                    <div class="video-container center-align">
                        <div class="divider"></div>
                        <p>"Through technology, these fishers can offer us a different food system on a platter. It also
                            offers us an opportunity to move from
                            conservation <i>for</i> to conservation <i>with</i> fisherfolk."</p>
                        <strong><small>- Dr Serge Raemaekers, ABALOBI Co-founder</small></strong>
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
        </div>
        <div class="row hide-on-large-only">
            <div class="container">
                <div class="center-align">
                    <div class="divider"></div>
                    <p>"Through technology, these fishers can offer us a different food system on a platter. It also
                        offers us an opportunity to move from
                        conservation <i>for</i> to conservation <i>with</i> fisherfolk."</p>
                    <strong><small>- Dr Serge Raemaekers, ABALOBI Co-founder</small></strong>
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- FOOTER -->
    <footer class="page-footer">
        <div class="container">
            <div class="row">
                <div class="col l12 s12">
                    <h5 class="white-text">{{ 'POWERED_BY_FOOTER' | translate }}</h5>
                    <p class="pFooter">{{ 'ABALOBI_IS_A' | translate}}</p>
                    <ul>
                        <li>
                            <a target="_blank" href="mailto:hello@abalobi.org" class="grey-text text-lighten-4">
                                <i class="fas fa-envelope-square"></i>
                                <span class="social-text">{{ 'CONTACT_URL' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="http://www.abalobi.org" class="grey-text text-lighten-4">
                                <i class="fas fa-globe"></i>
                                <span class="social-text">www.abalobi.org</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/abalobi.org/" class="grey-text text-lighten-4">
                                <i class="fab fa-facebook-square"></i>
                                <span class="social-text">abalobi.org</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://twitter.com/abalobi_app?lang=en" class="grey-text text-lighten-4">
                                <i class="fab fa-twitter-square"></i><span class="social-text">&#64;abalobi_app</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/abalobi_app/" class="grey-text text-lighten-4">
                                <i class="fab fa-instagram"></i><span class="social-text">&#64;abalobi_app</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col l4 offset-l2 s12">

                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <div class="container">
                <span>
                    © {{ currYear }} ABALOBI version: {{ version }}
                </span> &emsp;
                <br />
                <a class="modal-trigger clickable grey-text text-lighten-4 small-foot"
                    data-target="imageCreditsModal">{{ 'CATCH_IMG_CREDITS' | translate }}</a>
            </div>
        </div>
    </footer>

</ng-container>

<!-- Modal Structure -->
<div id="sendSMSModal" class="modal" *ngIf="cBatch != null && showError !== true">
    <div class="modal-content">
        <!-- one of two fishers -->
        <h5 *ngIf="cBatch.fishers.length <= 2 && cBatch.fishers.length > 0">
            {{ 'CATCH_SEND_MSG_TO' | translate }} {{cBatch.fishers[0].FirstName__c}}
            <span *ngIf="cBatch.fishers.length > 1">
                {{ 'CATCH_AND' | translate }} {{cBatch.fishers[1].FirstName__c}}
            </span>
        </h5>
        <!-- three or more fishers -->
        <h5 *ngIf="cBatch.fishers.length > 2">Send a message to the fishers</h5>
        <br>
        <div class="input-field col s12">
            <small class="small-small"><strong>{{ 'CATCH_NAME_NUMBER_OPTIONAL' | translate }}</strong></small>
            <input id="contact" placeholder="Enter your name" type="text" [(ngModel)]="user_contact_detail">
        </div>
        <div class="input-field col s12">
            <small class="small-small"><strong>{{ 'CATCH_YOUR_MSG' | translate }}</strong></small>
            <textarea type="text" maxlength="256" class="materialize-textarea"
                placeholder="Enter a message" [(ngModel)]="thankYouMsg"></textarea>
        </div>
    </div>

    <div class="modal-footer">
        <a target="_blank" role="button" class="modal-action modal-close btn waves-effect  btn-primary modal-button cancel-msg">
            {{ 'CANCEL' | translate }}
        </a>
        <a target="_blank" role="button" class="btn waves-effect btn-primary-color-light modal-button" (click)="sendThankYouSMS()">
            {{ 'SEND' | translate }}
        </a>
    </div>
</div>

<div id="workingWithSASSI" class="modal" *ngIf="cBatch != null && showError !== true">
    <!-- TODO MT refactoring needed - SASSI is a south african inititiative. Is this relevant to other tenants? -->
    <div class="modal-content">
        <p>
            {{ 'CATCH_ALSO_PARTNERS_WITH' | translate }}
        </p>
        <!--<ul style="display: list-item;list-style-type:disc">-->
        <ul>
            <li class="bulleted">{{ 'CATCH_WHAT_IS_IT' | translate }}</li>
            <li class="bulleted">{{ 'CATCH_WHERE_FROM' | translate }}</li>
            <li class="bulleted">{{ 'CATCH_HOW_CAUGHT' | translate }}</li>
            <li class="bulleted">{{ 'CATCH_WHO_CAUGHT' | translate }}Who caught it?</li>
        </ul>
        <p>
            {{ 'CATCH_CONSUMER_HAS_ANSWERS' | translate}}
        </p>
        <p>
            {{ 'CATCH_BUILDING_PARTNERSHIP' | translate }}
        </p>
        <p>
            {{ 'CATCH_FIND_SASSI' |translate }}
            <a target="_blank" href="http://wwfsassi.co.za/sassi-list/">
                wwfsassi.co.za/sassi-list/
            </a>
            <br>
            {{ 'CATCH_DOWNLOAD_SASSI' | translate }}
            <a target="_blank" href="http://wwfsassi.co.za/sassi-app/">
                wwfsassi.co.za/sassi-app/
            </a>
        </p>
    </div>
    <div class="modal-footer">
        <!--<a target="_blank" class="btn waves-effect white black-text darken-text-2" (click)="sendThankYouSMS()">Send</a>-->
        <a target="_blank" class="modal-action modal-close btn waves-effect white black-text darken-text-2">OK</a>
    </div>
</div>

<div id="whyMoreSpeciesModal" class="modal modalfix" *ngIf="cBatch != null && !showError">
    <div class="modal-content whySpecies">
        <h5>{{ 'CATCH_WHY_MORE_THAN_ONE' | translate }}</h5>
        <br>
        <p>
            {{ 'CATCH_FWS_PROMOTES' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <a target="_blank" class="modal-action modal-close btn waves-effect white black-text darken-text-2">
            {{ 'CLOSE' | translate }}
        </a>
    </div>
</div>

<div id="methodInfoModal" class="modal" *ngIf="cBatch != null && !showError">
    <div class="modal-content">
        <p appHtmlFormatter [innerHTML]="methodDescription"></p>

    </div>
    <div class="modal-footer">
        <a target="_blank" class="modal-action modal-close btn waves-effect white black-text darken-text-2">
            {{ 'CLOSE' |  translate }}
        </a>
    </div>
</div>

<div id="incorrectEstablishmentModal" class="modal modal-fixed-footer" *ngIf="cBatch != null && !showError">
    <div class="modal-content">

        <h5>{{ 'CATCH_THANK_YOU_FOR_CHOOSING' | translate }}
            <a target="_blank" href="https://www.instagram.com/explore/tags/fishwithastory">
                {{ "HASHTAG" | translate }},
            </a>
            {{ "CATCH_THEREBY_SUPPORTING" | translate }}
            <span *ngIf="batch_buyer_name == null">
                ...
            </span>
            <span *ngIf="batch_buyer_name != null">
                {{ 'CATCH_YOU_ARE_AT' | translate }} {{batch_buyer_name}}
                <a class="modal-trigger clickable" data-target="incorrectEstablishmentModal">
                    <i class="material-icons right">more_vert</i>
                </a>
            </span>.
            {{ 'CATCH_NOT_CORRECT_EST' | translate }}
        </h5>
        <br>
        <strong>
            <p>{{ 'CATCH_REPORT' | translate}}</p>
        </strong>
        <div class="input-field col s12">
            <small class="small-small"><strong>
                    {{ 'CATCH_WHERE_DINING' | translate }}
                </strong></small>
            <textarea id="reported-establishment" placeholder="Name of establishment and any comments" type="text"
                class="validate" [(ngModel)]="reported_establishment"></textarea>
        </div>
        <div class="input-field col s12">
            <small class="small-small">
                <strong>{{ 'CATCH_NAME_OPTIONAL' | translate }}</strong>
            </small>
            <input id="name" placeholder="Name" type="text" class="validate" [(ngModel)]="reporter_name">
        </div>
        <div class="input-field col s12">
            <small class="small-small">
                <strong>{{ 'CATCH_EMAIL_OPTIONAL' | translate }}</strong>
            </small>
            <input id="email" placeholder="E-mail Address" type="email" class="validate" [(ngModel)]="reporter_email">
        </div>
    </div>
    <div class="modal-footer">
        <a class="btn waves-effect white black-text darken-text-2" (click)="reportEstablishment()">
            {{ 'CATCH_REPORT' | translate }}
        </a>
        <a class="modal-action modal-close btn waves-effect white black-text darken-text-2">
            {{ 'CANCEL' | translate }}
        </a>
    </div>
</div>

<div id="oldListingModal" class="modal modal-fixed-footer" *ngIf="cBatch != null && !showError">
    <div class="modal-content">
        <p>
            {{ 'CATCH_STORY_REFERS' | translate }}
        </p>
        <strong>
            <p>{{ 'CATCH_REPORT' | translate }}</p>
        </strong>
        <div class="input-field col s12">
            <small class="small-small">
                <strong>
                {{ 'CATCH_WHERE_DINING' | translate }}
            </strong>
        </small>
            <textarea id="reported-establishment-old-listing" placeholder="Name of establishment and any comments"
                type="text" class="validate" [(ngModel)]="reported_establishment_old_listing"></textarea>
        </div>
        <div class="input-field col s12">
            <small class="small-small">
                <strong>
                    {{ 'CATCH_NAME_OPTIONAL' | translate }}
                </strong>
            </small>
            <input id="name-old-listing" placeholder="Name" type="text" class="validate" [(ngModel)]="reporter_name_old_listing">
        </div>
        <div class="input-field col s12">
            <small class="small-small">
                <strong>{{ 'CATCH_EMAIL_OPTIONAL' | translate }}</strong>
        </small>
            <input id="email-old-listing" placeholder="E-mail Address" type="email" class="validate" [(ngModel)]="reporter_email_old_listing">
        </div>
    </div>
    <div class="modal-footer">
        <a class="btn waves-effect white black-text darken-text-2" (click)="reportEstablishmentOldListing()">
            {{ 'CATCH_REPORT' | translate }}
        </a>
        <a class="modal-action modal-close btn waves-effect white black-text darken-text-2">
            {{ 'CANCEL' | translate }}
        </a>
    </div>
</div>

<!-- NB: this section needs the *ngIf='true' othewise the translate pipe breaks. -->
<div id="imageCreditsModal" class="modal" *ngIf='true'>
    <div class="modal-content">
        <div class="creditHeader">
            <div class="creditsTitle">{{ 'CATCH_IMG_CREDITS' | translate }}</div>
            <a class="modal-close"><img src="assets/icons/icon_close_white.png" width="30" /></a>
        </div>
        <ul class="collection">
            <li class="collection-item break-words"><b>Bigeye Scad:</b> http://www.thisfish.info</li>
            <li class="collection-item break-words"><b>Bigeye Tuna:</b> https://bloximages.newyork1.vip.townnews.com
            </li>
            <li class="collection-item break-words"><b>Bluespotted Jobfish:</b> https://www.fishbase.se</li>
            <li class="collection-item break-words"><b>Candelamoa Parrotfish:</b> https://www.biolib.cz</li>
            <li class="collection-item break-words"><b>Common Dolphinfish:</b> https://cdn.shopify.com (B. Gurd
                Giuespie)</li>
            <li class="collection-item break-words"><b>Cinnabar Goatfish:</b> http://fishillust.com</li>
            <li class="collection-item break-words"><b>Dash and Dot Goatfish:</b> http://cdn.shopify.com</li>
            <li class="collection-item break-words"><b>Elongate Surgeonfish:</b> http://collections.nmnh.si.edu</li>
            <li class="collection-item break-words"><b>Indian Mackerel:</b> https://www.fishsource.org</li>
            <li class="collection-item break-words"><b>Kawakawa:</b> https://4.bp.blogspot.com (Diane Rome Peebles)</li>
            <li class="collection-item break-words"><b>Longface Emperor:</b> http://img.21food.com</li>
            <li class="collection-item break-words"><b>Long-legged Spiny Lobster:</b> http://img.21food.com</li>
            <li class="collection-item break-words"><b>Mahsena Emperor:</b> http://skaphandrus.com</li>
            <li class="collection-item break-words"><b>Moontail Seabass:</b> https://www.vetofish.com</li>
            <li class="collection-item break-words"><b>Octopus:</b> https://upload.wikimedia.org (Comingio Merculiano)
            </li>
            <li class="collection-item break-words"><b>Pickhandle Barracuda:</b> http://www.fishao.cz</li>
            <li class="collection-item break-words"><b>Pronghorn Spiny Lobster:</b> https://inpn.mnhn.fr (R. Swainston)
            </li>
            <li class="collection-item break-words"><b>Rainbow Runner:</b> https://scandposters.com</li>
            <li class="collection-item break-words"><b>Redbanded Grouper:</b> https://www.vetofish.com</li>
            <li class="collection-item break-words"><b>Redspot Emperor:</b> http://fishesofaustralia.net.au</li>
            <li class="collection-item break-words"><b>Red smalltooth Jobfish:</b> https://static.inaturalist.org</li>
            <li class="collection-item break-words"><b>Sailfish:</b> https://animaldiversity.org</li>
            <li class="collection-item break-words"><b>Spanner Crab:</b> https://www.yellowbkroad.com/fish</li>
            <li class="collection-item break-words"><b>Spanner Crab:</b> http://fish.gov.au</li>
            <li class="collection-item break-words"><b>Spotted Eagle Ray:</b> https://i.pinimg.com</li>
            <li class="collection-item break-words"><b>Spotted Coral Trout:</b> https://fish.gov.au</li>
            <li class="collection-item break-words"><b>Streamlined Spinefoot:</b> http://fishesofaustralia.net.au</li>
            <li class="collection-item break-words"><b>Skipjack Tuna:</b> http://thisfish.info</li>
            <li class="collection-item break-words"><b>Tomato Hind:</b> https://www.vetofish.com/sites/vetofish.com</li>
            <li class="collection-item break-words"><b>Twospot Red Snapper:</b> http://fishesofaustralia.net.au</li>
            <li class="collection-item break-words"><b>Variegated Emperor:</b> http://fishesofaustralia.net.au</li>
            <li class="collection-item break-words"><b>Wahoo:</b> https://cdn.shopify.com (B. Gurd Giuespie)</li>
            <li class="collection-item break-words"><b>Yellowspotted Trevally:</b> http://www.thisfish.info</li>
            <li class="collection-item break-words"><b>Yellowtail Emperor:</b> http://www.fishwisepro.com</li>
            <li class="collection-item break-words"><b>Yellowfin Tuna:</b> http://i.cdn-surfline.com (Carey Ren)</li>
        </ul>

        <div class="modal-footer">
            <a type="button" class="modal-action modal-close btn-flat waves-effect add">
                {{ 'CLOSE' | translate }}
            </a>
        </div>
    </div>

</div>
